/**
* Transform a list in several list columns.
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {

  var arrangeColumns = function (target, element, num_cols) {
    var clone = $(element).clone(),
        items = clone.find('li'),
        items_col =  Math.ceil(items.length / num_cols),
        row = $('<div class="row js-listcols-divided"></div>');

    // Mark children with a class.
    $(items)
      .filter(function( index ) {
        return $(this).parentsUntil(target).length > 1;
      })
        .addClass('children');

    // Divide total items into Bootstrap columns
    for (var i = 0; i < num_cols; i++) {
      var start = i * items_col,
          end = (i + 1) * items_col,
          bs_col = $('<div class="col-sm-6 col-md-4 col-lg-3"></div>'),
          list = $('<ul></ul>');

      list
        .append($(items).slice(start, end))
        .addClass($(element).attr('class'))
        .removeClass('js-listcols');
      bs_col.append(list);
      row.append(bs_col).show();
    }

    $(element)
      .hide()
      .after(row);
  };

  var compactList = function (target) {
    var toggle_open_txt = 'Browse... <i class="fa fa-sort-desc"></i>',
        toggle_close_txt = 'Browse... <i class="fa fa-sort-asc"></i>',
        toggle_btn = '<span class="js-listcols-toggle-btn">' + toggle_open_txt + '</span>';

    // Add click event to toggle
    var btnToggle = $(toggle_btn).click(function() {
      if($(target).hasClass('expanded')) {
        $(target)
          .slideUp()
          .removeClass('expanded')
          .siblings('.js-listcols-toggle-btn')
            .html(toggle_open_txt);
      }
      else {
        $(target)
          .slideDown()
          .addClass('expanded')
          .siblings('.js-listcols-toggle-btn')
            .html(toggle_close_txt);
      }
    });

    $(target)
      .addClass('js-listcols-compact')
      .hide()
      .before(btnToggle)
      .after(btnToggle.clone(true));
  };

  var columnDivider = function () {
    var num_cols = 2,
        target = $('.js-listcols'),
        windowW = window.innerWidth;

    if (windowW < 768) {
      // Remove splitted columns and merge all in one
      $(target).each(function() {
        if ($(this).hasClass('js-listcols-compact')) {
          $(this)
            .show()
            .next('.js-listcols-divided')
              .hide();
        }
        else {
          compactList(this);
        }
      });
    }
    else {
      // Divide column in 2 or 4 columns depending of the screen size
      if (windowW > 992) {
        num_cols = 3;
      }
      if (windowW > 1200) {
        num_cols = 4;
      }

      $(target).each(function() {
        var arrange_columns = true;
            sibling = $(this).next('.js-listcols-divided');

        if (sibling.length && sibling.children().length == num_cols) {
          arrange_columns = false;
        }

        // Delete sibling if columns layout switched
        if (sibling.length && arrange_columns) {
          sibling.remove();
        }

        if (arrange_columns) {
          arrangeColumns(target, this, num_cols);
        }
      });
    }
  };

  window.addEventListener('load', columnDivider);
  window.addEventListener('resize', columnDivider);

};