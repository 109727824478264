var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer'),
    bootstrapCarouselSwipe = require('bootstrap-carousel-swipe');

/**
 * Open videos on a carousel located in a modal window by clicking on a trigger
 */
module.exports = function (params) {
    'use strict';

    var current,
        videos = params.videos || [],
        pages = params.collection || [],
        players = {},
        triggers = $('a[href^="#slide"]'),
        videoCarousel = $('#hcf-health-talks-video-carousel');

    videoCarousel.on({
        "slide.bs.carousel": stopPlayer,
        "slid.bs.carousel": function () {

            // check if this page has any videos on it that need to be started
            var slideNumber = $('.active', videoCarousel).data('slide-to');
            pages[slideNumber].webBlocks.forEach(function(thisBlock){
                thisBlock.forEach(function (webBlockItem) {
                    if (webBlockItem.blockType == 2) {
                        current = webBlockItem.videoIndex;
                        startPlayer();
                    }
                });
            });

            // Carousel cycles automatically after changing slides
            // so we have to pause it again after each slide change.
            videoCarousel.carousel('pause');
        }
    });

    // Open correct video slide when clicking video teaser link
    triggers.each(function (i, el) {
        $(el)
            .addClass('js-video-carousel-trigger')
            .attr('data-toggle', 'modal')
            .attr('data-target', '#hcf-health-talks-video-carousel')
            .attr('data-slide-to', $(el).attr('href').split('-').pop())
            .click(onTriggerClick);
    });

    // on inital display check if there are any videos on the first page that should be setup
    pages[0].webBlocks.forEach(function(thisBlock) {
        thisBlock.forEach(function (webBlockItem) {
            if (webBlockItem.blockType == 2) {
                current = webBlockItem.videoIndex;
                ensurePlayer(current);
            }
        });
    });

    function startPlayer() {
        //current = $('.active', videoCarousel).data('slide-to');
        ensurePlayer(current).on("ready", function() {
          ensurePlayer(current).setMute(false);
          ensurePlayer(current).play();
        });
    }

    function stopPlayer() {
        if (current !== undefined) {
            ensurePlayer(current).stop();
        }
    }

    function onTriggerClick(e) {
        // Initialize carousel - not automatically animated due to 'data-interval="false"'.
        videoCarousel.carousel($(e.currentTarget).data('slide-to'));
        e.preventDefault();
    }

    function ensurePlayer(id) {
        if (!players[id]) {
            // TODO: Currently disabling preroll ads in modal/carousel as they
            //       don't play nice.
            if (videos[id].advertising_tag) {
                delete videos[id].advertising_tag;
            }
            players[id] = initPlayer(videos[id]);
        }
        return players[id];
    }
};

