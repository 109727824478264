var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

/**
 * Play videos when selected/clicked from a list of video images
 */
module.exports = function (params) {
  'use strict';

  var videos = params.videos || [],
      players = {},
      triggers = $('.js-pharma-sample-video'),
      current = 0,
      topVideoPlayer = jwplayer('top-video');

  // Play specific video from video image click
  triggers.each(function (i, el) {
    $(el).click(onVideoTriggerClick);
  });

  function startPlayer() {
    if (typeof topVideoPlayer.stop === 'function'  ) {
      topVideoPlayer.stop();
    }

    ensurePlayer(current).play();
  }

  function stopPlayer() {
    if (current !== undefined) {
      ensurePlayer(current).stop();
    }
  }

  function onVideoTriggerClick(e) {
    current = $(e.currentTarget).attr('data-target');
    startPlayer();
  }

  function ensurePlayer(id) {
    if (!players[id]) {
      // TODO: Currently disabling preroll ads in modal/carousel as they
      //       don't play nice.
      if (videos[id].advertising_tag) {
        delete videos[id].advertising_tag;
      }
    }
    players[id] = initPlayer(videos[id]);

    // set title and description
    var titleField = $('#pharma-sample-video-title');
    var descriptionField = $('#pharma-sample-video-description');
    titleField.text(videos[id].title);
    descriptionField.text(videos[id].description);

    return players[id];
  }

  // initialize the first video in the player
  ensurePlayer(0);

};

