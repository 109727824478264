/**
 * Display modal windows on user profile
 */
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function (params) {
  var dataTrigger = $('.user-modal'),
      headerDisplay = $('#pract-header-tell-me-more'),
      players = {},
      introHelpModal = $('#hcf-logged-in-pract-help-modal'),
      accreditedVideoTrigger = $('#hcf-accredited-video-trigger'),
      hideOptionsTrigger = $('#hcf-hide-options'),
      showOptionsTrigger = $('#hcf-show-my-options'),
      practIntroHelpTrigger = $('.hcf-logged-in-pract-intro-help'),
      setFeaturedIndustryBannerUrl = params.featuredBannerUpdateUrl;

  var images = [
    '/images/home_page_health_workers_04.3.png',
    '/images/home_page_health_workers_01.2.png'
  ];

  $('[data-toggle="popover"]').popover(
      { delay: 0,
        trigger: 'hover',
        container: 'body',
        placement: 'bottom'
      }
  );

  $(window).scroll(function() {
    if ($("#accreditedVideoInfo").length) {  // if exists
      if (isVisible($("#accreditedVideoInfo"))) {
        setTimeout(function () {
          $('#accreditedVideoInfo').collapse('toggle');
        }, 5000);
        $(window).unbind('scroll');
      }
    }
  });

  function isVisible($el) {
    var winTop = $(window).scrollTop();
    var winBottom = winTop + $(window).height();
    var elTop = $el.offset().top;
    var elBottom = elTop + $el.height();
    return ((elBottom<= winBottom) && (elTop >= winTop));
  }


  // set Featured Industry Banner to On or Off
  if (params.featuredIndustryBannerFlag == 1){
    $('#rdFeaturedIndustryOn').attr('checked', 'checked');
    $('#pract-logged-on-industry-banner-action').addClass('hcf-margin-top-20');
    //googletag.cmd.push(function() { googletag.display('pract-logged-in-300-250-1');});
  }
  else
  {
    $('#rdFeaturedIndustryOff').attr('checked', 'checked');
    $('#pract-logged-on-industry-banner-action').removeClass('hcf-margin-top-20');
  }


  dataTrigger.on('click', function() {
    currentTarget = $(this).attr('modal-target');

    $('#' + currentTarget).modal('show');
  });

  // radio buttons for featured industry banner ad
  $("input[name='featuredindustry']").change(function(e){
    var loggedInBanner = $('#pract-logged-in-300-250-1');
    var practitionerId = $(this).data("pract-id");
    var radioVal = 0;

    if($(this).val() == 'On') {
      loggedInBanner.show();
      radioVal = 1;
      $('#pract-logged-on-industry-banner-action').addClass('hcf-margin-top-20');
      //googletag.cmd.push(function() { googletag.display('pract-logged-in-300-250-1');});
    } else if($(this).val() == 'Off'){
      loggedInBanner.hide();
      $('#pract-logged-on-industry-banner-action').removeClass('hcf-margin-top-20');
    }

    $.ajax({ type: "POST", url: setFeaturedIndustryBannerUrl, data: {radioval: radioVal, practId: practitionerId}, error: function(xhr, status, error) {myerror(xhr, status, error);} });
  });

  function myerror(xhr, status, error){
  }

  hideOptionsTrigger.on('click', function() {
    hidePractOptions();
  });

  headerDisplay.on('click', function() {
    tellMeMore = $('#pract-header-tell-me-more');
    if (tellMeMore.data("action") === "hidden"){
      tellMeMore.data("action", "showing");
      tellMeMore.text("OK, got it.");
      $('#pract-header-more-info').css("display", "block");
    }
    else {
      hidePractOptions();
    }
  });

  practIntroHelpTrigger.on('click', function() {
    introText = $(this).prev('.hcf-logged-in-pract-intro-title').text();
    introImageSrc = $(this).parent().prev().attr('src');
    $('#hcf-logged-in-help-image').attr("src",introImageSrc);
    $('#hcf-logged-in-help-title').text(introText);
    $('#hcf-logged-in-help-description').text($(this).parent().data("intro-description"));
    introHelpModal.modal('show');
  });

  showOptionsTrigger.on('click', function () {
    $('#pract-header-info').show();
    $('#hcf-show-my-options').css("display", "none");
  });

  function hidePractOptions(){
    $('#pract-header-info').hide();
    $('#hcf-show-my-options').css("display", "block");
  }

  accreditedVideoTrigger.on('click', function() {
    caretIcon = $('#hcf-accredited-video-caret');
    accreditedVideoContent = $('#accreditedVideoInfo');
    if (caretIcon.hasClass('fa-caret-down')){
      caretIcon.addClass('fa-caret-up').removeClass('fa-caret-down');
      accreditedVideoContent.collapse('hide');
    }
    else{
      caretIcon.addClass('fa-caret-down').removeClass('fa-caret-up');
      accreditedVideoContent.collapse('show');
    }
  });

  var banner = document.getElementById("pract-header-container");

  if (banner) {
    var min = 0,
        max = images.length - 1;

    //Returns a random integer between min (inclusive) and max (inclusive)
    var i = Math.floor(Math.random() * (max - min + 1)) + min;

    banner.style.backgroundImage = "url(" + images[i] + ")";
  }

  $('.mypopover').each(function () {
    var $elem = $(this);
    var menuNumber = $elem.data('menu-number');
    var popoverWidth = $elem.data('popover-width');
    var menuPopOverHtml = $('#menunumber' + menuNumber).html();
    $elem.popover({
      placement: 'bottom',
      trigger: 'hover',
      html: true,
      container: $elem,
      content: menuPopOverHtml
    });

    $elem.on("show.bs.popover", function(e){
      $elem.data("bs.popover").tip().css({"max-width": popoverWidth});
    });
  });
};