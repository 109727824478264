var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer'),
    bootstrapCarouselSwipe = require('bootstrap-carousel-swipe');

/**
 * Open videos on a carousel located in a modal window by clicking on a trigger
 */
module.exports = function (params) {
  'use strict';

  var current = 0,
      videos = params.videos || [],
      players = {},
      videoModal = $('#hcf-cme-video-modal'),
      triggers = $('#hcf-cme-video'),
      videoCarousel = $('#hcf-health-talks-video-carousel');

  videoModal.on('show.bs.modal', startPlayer);
  videoModal.on('hide.bs.modal', stopPlayer);

  triggers.click(function () {
      videoModal.modal('show');
  });

  function startPlayer() {
    current = 0;
    ensurePlayer(current).on("ready", function() {
      ensurePlayer(current).play();
    });
  }

  function stopPlayer() {
    if (current !== undefined) {
      ensurePlayer(current).stop();
    }
  }

  function ensurePlayer(id) {
    if (!players[id]) {
      // TODO: Currently disabling preroll ads in modal/carousel as they
      //       don't play nice.
      if (videos[id].advertising_tag) {
        delete videos[id].advertising_tag;
      }
      players[id] = initPlayer(videos[id]);
    }
    return players[id];
  }

};

