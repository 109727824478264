var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

// Module params are:
// searchUrl -- URL for submitting the search.
// contactUsUrl -- URL for the contact us page.
// loginUrl -- URL for the login page.
// inputIds -- array with element ids for inputs:
//     firstName
//     lastName
//     practitionerType
//     city
//     province
// displayIds -- array with element ids for various displays:
//     start        - Initial blank page shown on page load.
//     spinner      - The spinner (show during search, hidden before & after).
//     list         - The element that holds the results.
//     chosen       - The element that holds their chosen profile.
//     completeProfile  - Show this if they choose to create instead of claim.

function show(ele) {
    return ele.toggleClass('hidden', false);
}

function hide(ele) {
    return ele.toggleClass('hidden', true);
}

function isVisible(ele) {
  return (! ele.hasClass('hidden'));
}

function disableForm(ele) {
  ele.find(':input').prop('disabled', true);
}

function enableForm(ele) {
  ele.find(':input').prop('disabled', false);
}

module.exports = function(params) {
  var m = {   // Namespace Module level variables.
    urls: {
      search: params.searchUrl,
      contactUs: params.contactUsUrl,
      login: params.loginUrl
    },
    inputs: {
      firstName: $("#" + params.inputIds.firstName),
      lastName: $("#" + params.inputIds.lastName),
      practitionerType: $("#" + params.inputIds.practitionerType),
      city: $("#" + params.inputIds.city),
      province: $("#" + params.inputIds.province)
    },
    panels: {
      start: $("#" + params.displayIds.start),
      spinner: $("#" + params.displayIds.spinner),
      list: $("#" + params.displayIds.list),
      chosen: $("#" + params.displayIds.chosen),
      completeProfile: $("#" + params.displayIds.completeProfile)
    },
    lastKbTimeStamp: null,
    lastSearch: {}
  };

  $.each(m.inputs, function(name, selector) {
    selector.change(inputChange);
    selector.keyup(keyboardInputChange);
  });

  // Wait for at least 300 milliseconds after the last keyboard input event
  // before launching the search.  This will soften the load on the server.
  function keyboardInputChange(e) {
    var d = new Date();
    var myTimeStamp = m.lastKbTimeStamp = d.getTime();

    setTimeout(function() {
      if (m.lastKbTimeStamp === myTimeStamp) {
        inputChange(e)
      }
    }, 300);
  }

  function inputChange(e) {
    if (isVisible(m.panels.completeProfile)) {
      return;  // They are completing the profile, no need to search anymore.
    }

    var firstName = m.inputs.firstName.val().trim();
    var lastName = m.inputs.lastName.val().trim();
    var practitionerType = m.inputs.practitionerType.val();
    var city = m.inputs.city.val().trim();
    var province = m.inputs.province.val();

    if (firstName.length === 0        ||
        lastName.length === 0         ||
        practitionerType.length === 0 ||
        city.length === 0             ||
        province.length === 0         ||
        (firstName === m.lastSearch.firstName               &&
         lastName === m.lastSearch.lastName                 &&
         practitionerType === m.lastSearch.practitionerType &&
         city === m.lastSearch.city                         &&
         province === m.lastSearch.province                 )) {

      // Not enough data yet or nothing was changed from last time, ignore.
      return;
    }

    showPanel(m.panels.spinner);

    // Also save as the m.lastSearch for the conditional check above.
    var searchData = m.lastSearch = {
      firstName: firstName,
      lastName: lastName,
      practitionerType: practitionerType,
      city: city,
      province: province
    };

    $.post(m.urls.search, searchData)
     .done(showSearchResults)
     .fail(showSearchError)
  }

  function showPanel(panel) {
    for (var p in m.panels) {
      if (m.panels.hasOwnProperty(p) && m.panels[p] !== panel) {
        hide(m.panels[p]);
      }
    }
    show(panel);
  }

  function showSearchResults(response) {
    m.panels.list.empty();
    m.panels.list.append(createResultsView(response));
    showPanel(m.panels.list);
  }

  function showSearchError(err) {
    m.panels.list.empty();
    m.panels.list.append([
      '<div class="alert alert-danger" role="alert">',
      '<h4>Search failed.</h4>',
      '<p>Error: ' + err.statusText,
      '<br>Error code: ' + err.status + '</p>',
      '</div>'
    ].join(''));
    showPanel(m.panels.list);
  }

  function createResultsView(r) {
    var count = r.results.length;
    var title;
    if (r.more_available) {
      title = $([
        '<h4>',
        'We\'ve returned the ' + count + ' closest matching results. ',
        'If you don\'t see your profile here you can try searching again or ',
        '<a href="javascript:void(0)">create a new profile</a>.',
        '</h4>'
      ].join(''));
      title.find('a').click(showCompleteProfile);
    } else {
      title = $('<h4>' + count + ' matching profiles found:</h4>');
    }

    var list = $('<ul id="claim-search-results" class="list-group">');
    for (var i = 0; i < r.results.length; i++) {
      list.append(createPractitionerItem(r.results[i], i));
    }

    var completeLink = $([
      '<a href="javascript:void(0)">',
      'None of these are my profile, I\'ll complete a new one',
      '</a>'
    ].join(''))
    completeLink.addClass('btn btn-lg btn-primary btn-primary-alt btn-block');
    completeLink.click(showCompleteProfile);

    var view = $('<div>')
      .append(title)
      .append(list)
      .append(completeLink);

    return view
  }

  function createPractitionerItem(p, index) {
    var item = $('<li>');
    item.attr('id', 'practitioner-search-result-' + index);
    item.addClass('profile-to-claim list-group-item media');

    item.append(createPractitionerBlock(p, index));

    var claimLink = $([
      '<a class="claim btn btn-primary pull-right" href="javascript:void(0)">',
      'Claim this profile',
      '</a>'
    ].join('')).click(function(e){return showChoice(p);});

    item.append(claimLink);

    return item;
  }

  function createPractitionerBlock(p) {
    var business = p.business_name || '';
    var typeTitle = p.type_title || '';
    var image = p.image_url || '/images/default_practitioner.png';

    var names = [];
    if (p.title) names.push(p.title);
    if (p.first_name) {
      names.push('<span class="first-name">' + p.first_name + '</span>');
    }
    if (p.last_name) names.push(p.last_name);

    var locs = [];
    if (p.city) locs.push(p.city);
    if (p.province) locs.push(p.province);

    var block = $([
      '<div class="pull-left"><img src="' + image + '" class="media-object"></div>',
      '<div class="media-body">',
      '<h5 class="media-heading practitioner-name">' + names.join(' ') + '</h5>',
      '<h6 class="practitioner-type">' + typeTitle + '</h6>',
      '<h6>' + business + '</h6>',
      '<h6>' + locs.join(', ') + '</h6>',
      '</div>',
    ].join(''));

    return block;
  }

  function showChoice(profile) {
    disableForm($('#form-practitioner-signup-email'));

    var cancelLink = $([
      '<a class="cancel-claim" href="javascript:void(0)">',
      'show matching profiles again',
      '&nbsp;<i class="fa fa-caret-down"></i>',
      '</a>'
    ].join('')).click(function(e){
      enableForm($('#form-practitioner-signup-email'));
      showPanel(m.panels.list);
    });

    var cancelBlock = $('<div>').addClass('hcf-claim-profile-cancel');
    cancelBlock.append(cancelLink);

    var claim = $('<div>');
    claim.addClass('profile-to-claim list-group-item media');
    claim.append(createPractitionerBlock(profile));
    claim.append(createClaimActionBlock(profile));

    var view = $('<div>');
    view.append(cancelBlock);
    view.append(claim);

    m.panels.chosen.empty();
    m.panels.chosen.append(view);

    showPanel(m.panels.chosen);
  }

  function createClaimActionBlock(profile) {
    var block = $('<div>');

    var parts = [];
    if (profile.title) parts.push(profile.title);
    if (profile.first_name) parts.push(profile.first_name);
    if (profile.last_name) parts.push(profile.last_name);
    var name = parts.join(' ');

    parts = [];
    if (name.length) parts.push(name);
    if (profile.type_title) parts.push(profile.type_title);
    var description = 'This profile'
    if (parts.length > 0) {
      description = 'The profile for ' + parts.join(', ');
    }

    if (profile.has_been_claimed) {
      block.append(loginInsteadBlock(description, profile));
    } else if (profile.has_email) {
      block.append(emailClaimBlock(description, profile));
    } else {
      block.append(enterEmailClaimBlock(description, profile));
    }

    return block;
  }

  function emailClaimBlock(description, profile) {
    var block = $('<div>');

    var blurb = $([
      '<div class="blurb">',
      description + ' is already in our directory ',
      ' but it hasn\'t been claimed yet.',
      ' We\'ll send a confirmation to the email address we have for',
      ' this profile to allow you to complete the claiming process.',
      '<div>'
    ].join(''));

    var claimLink = $([
      '<a class="claim btn btn-primary pull-right" href="javascript:void(0)">',
      'Claim this profile',
      '</a>'
    ].join('')).click(function(e){
      claimIt($(this), blurb, profile);
      return false;   // Do not follow link.
    });

    block.append(blurb);
    block.append(claimLink);

    return block
  }

  function enterEmailClaimBlock(description, profile) {
    var block = $('<div>');

    var blurb = $([
      '<div class="blurb">',
      description + ' is already in our directory ',
      ' but it hasn\'t been claimed yet. If this is your profile please',
      ' provide your email address and we\'ll send you a confirmation email to',
      ' allow you to complete the claiming process.',
      '<div>'
    ].join(''));

    var emailForm = $([
      '<form action="#" method="post" class="hcf-claim-profile-form">',
      ' <div class="form-group">',
      '   <label for="email" class="required">Email:</label>',
      '   <input type="email" id="email" name="_email" required="required" ',
      '          class="form-control" placeholder="name@example.com">',
      ' </div>',
      '</form>'
    ].join('')).submit(function(e){
      claimIt($(this), blurb, profile);
      return false;   // Stop the form from actually submitting.
    });

    var claimLink = $([
      '<button type="submit" id="_submit" class="claim btn btn-primary pull-right">',
      'Claim this profile',
      '</button>'
    ].join(''))

    emailForm.append(claimLink);
    blurb.append(emailForm);
    block.append(blurb);
    return block;
  }

  function loginInsteadBlock(description, profile) {
    var block = $('<div>');

    var blurb = $([
      '<div class="blurb">',
      'There is already an account registered to this profile. ',
      'To sign in, or reset your password if you\'ve forgotten it, please ',
      'proceed to our <a href="' + m.urls.login + '">Log In</a> page.',
      '<div>'
    ].join(''));

    block.append(blurb);
    return block;
  }

  function claimIt(ele, blurbEle, profile) {
    if (ele.hasClass('selecting')) {
      return;     // Claim has already been submitted to server.
    }

    ele.toggleClass('selecting', true);
    $('a.cancel-claim').remove();    // No going back to search results now.

    var data = {};
    if (ele.is('form')) {
      data.email = ele.find('#email').val().trim();
    }

    $.post(profile.claim_it_url, data)
      .done(function(result) {
        hide(ele);
        return showClaimResult(blurbEle, profile, result);
      })
      .fail(function(err) {
        ele.toggleClass('selecting', false);
        return showClaimError(blurbEle, err);
      })
  }

  function showClaimResult(blurbEle, profile, result) {
    var firstName = profile.first_name || '';
    var newBlurb = $([
      '<div class="blurb panel panel-primary">',
      '<div class="panel-body">',
      '<p><i class="fa fa-check"></i></p>',
      ' <p>Thanks ' + firstName + '.  To allow you to confirm your account',
      ' we have sent an email to:<br>',
      result.obfuscated_email,
      ' <br>If you don\'t receive an email please',
      ' <a href="' + m.urls.contactUs + '">contact us</a></p>',
      '<div>',
      '<div>'
    ].join(''));

    blurbEle.replaceWith(newBlurb);
  }

  function showClaimError(blurbEle, err) {
    errorDescription = [
      'Error: ' + err.statusText,
      '<br>Error code: ' + err.status
    ].join('');

    if (err.responseJSON && err.responseJSON.error_msg) {
      errorDescription = err.responseJSON.error_msg;
    }

    var newBlurb = $([
      '<div class="blurb panel panel-danger">',
      '<div class="panel-body">',
      'We\'re sorry, the claim failed.',
      '<br>',
      errorDescription,
      '<div>',
      '<div>'
    ].join(''));

    blurbEle.replaceWith(newBlurb);
  }

  function showCompleteProfile(e) {
    showPanel(m.panels.completeProfile);
    return false;
  }

  inputChange();  // If browser forward button is used, and the form is filled.
}
