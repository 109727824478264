/**
* Controls equal height columns in different sections of the site
*
* @params (string) selector_wrapper: A CSS selector for the element that wraps all the equal height elements.
* @params (string) selector_item: A CSS selector to identify an equal height element from inside the wrapper.
*/


module.exports = function (params) {

  var selector_wrapper = params.selector_wrapper || '.js-equal-height-wrapper',
      selector_item = params.selector_item || '.js-equal-height-item',
      wrappers = document.querySelectorAll(selector_wrapper),
      eh_sets = []; // (eh_sets = Equal Height Sets)

  // Explanation of line bellow for beginners like me ;) >> http://youmightnotneedjquery.com/
  Array.prototype.forEach.call(wrappers, function(el, i){
    eh_sets.push({
      'wrapper': el,
      'items': el.querySelectorAll(selector_item)
    });
  });

  var equalHeight = function () {
    eh_sets.forEach(function(element) {
      var topHeight = 0,
          items = element.items;

      Array.prototype.forEach.call(items, function(item, i) {
        // in order to set equal heights, we must let the content flow freely first
        item.style.boxSizing = 'content-box';
        item.style.height = 'auto';

        if (item.offsetHeight > topHeight) {
          topHeight = item.offsetHeight;
        }
      });

      Array.prototype.forEach.call(items, function(item, i) {
        item.style.boxSizing = 'border-box';
        item.style.height = topHeight + 'px';
      });
    });
  };

  window.addEventListener('load', equalHeight);
  window.addEventListener('resize', equalHeight);
};

/*
//                          ---- DO NOT DELETE THIS CODE!!!!!  ----

// There is nothing wrong with this commented code, in fact it does the same thing that the
// code above but it depends on jQuery. The code above is pure javascript, so if you run
// into a problem that can't solved with vanilla Javascript you may like to use this code instead.


var $ = require('jquery');


module.exports = function (params) {

  var wrappers = $('.js-equal-height-wrapper'),
      eh_sets = []; // (eh_sets = Equal Height Sets)

  wrappers.each(function() {
    eh_sets.push({
      'wrapper': this,
      'items': $('.js-equal-height-item', this)
    });
  });

  var equalHeight = function () {
    eh_sets.forEach(function(element) {
      var topHeight = 0,
          items = element.items;

      // in order to set equal heights, we must let the content flow freely first
      items.css({
        'box-sizing': 'conent-box',
        'height': 'auto'
      });

      items.each(function() {
        if ($(this).outerHeight() > topHeight) {
          topHeight = $(this).outerHeight();
        }
      });

      items.css({
        'box-sizing': 'border-box',
        'height': topHeight + 'px'
      });
    });
  };

  $(window).bind("load", equalHeight);
  $(window).bind("resize", equalHeight);
};
*/
