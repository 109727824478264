/**
* Turn a select element in a Bootstrap select element.
* Using http://silviomoreto.github.io/bootstrap-select.
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  var elements = '.js-hcf-select';

  $(elements).each(function() {
    $(this).selectpicker();
  });
};