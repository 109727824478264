var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function(params) {
  var practitionerUrl = params.practitioner_url,
      sendEmailUrl = params.send_email_url,
      practitionerSelector = $('#email-invite-practitioner-select'),
      educationalLibrarySelector = $('#email-invite-library-select'),
      submitTrigger = $('#business-educational-library-invite'),
      emailInfoModal = $('#hcf-business-email-info-modal'),
      patientList = params.patient_list;

  var currentPractitionerSlug = '';

  practitionerSelector.change(function() {
    var emailSubject = $('#business_email_invite_subject');
    emailSubject.val($(this).find("option:selected").text() + ' - Educational Video Libraries');
    currentPractitionerSlug = $(this).find("option:selected").data('slug');

    //revalidate field
    $('#hcf-business-email-invite-form').formValidation('revalidateField', 'emailinvite[subject]');

  });

  educationalLibrarySelector.change(function () {
    var emailMessage = $('#business_email_invite_message');
    var currentPractitionerUrl = practitionerUrl.replace('SLUG-HOLDER', currentPractitionerSlug);
    emailMessage.val("This is a patient health education website with videos and action plans you may use to learn about and " +
    "help you manage your health condition(s) \n\n" +
    "Please subscribe to these FREE and regularly updated patient education videos. You can unsubscribe at any time and review the confidentiality agreement " +
    "which protects your information. \n\n" +
    "Here is the link to your prescribed patient education videos: " +
        "[Educational Library Link](" +  currentPractitionerUrl + "?hcf_library=1&email_invited=1&edlib=" + $(this).val() +
    ") \n\n" +
    "For informative videos on your specific prescribed medication please feel free to visit our medications page at: [Medications Page](https://www.healthchoicesfirst.com/medications) ." +
    "Enter your product DIN number on your prescription to view informative videos." +
    "\n\nRegards,\n\nThe HealthChoicesFirst team");

    $('#hcf-business-email-invite-form').formValidation('revalidateField', 'emailinvite[message]');
  });

  var emailInviteFormValidator = $('#hcf-business-email-invite-form').formValidation({
    framework: 'bootstrap',
    icon: {
      valid: 'fa fa-check',
      invalid: 'fa fa-times',
      validating: 'fa fa-refresh'
    },
    fields: {
      'emailinvite[firstName]': {
        validators: {
          notEmpty: {
            message: 'The first name is required'
          },
          stringLength: {
            min: 1,
            max: 120,
            message: 'The first name must be less than 120 characters'
          }
        }
      },
      'emailinvite[lastName]': {
        validators: {
          notEmpty: {
            message: 'The last name is required'
          },
          stringLength: {
            min: 1,
            max: 120,
            message: 'The last name must be less than 120 characters'
          }
        }
      },
      'emailinvite[email]': {
        validators: {
          notEmpty: {
            message: 'The email address is required'
          },
          emailAddress: {
            message: 'This is not a valid email address'
          }
        }
      },
      'emailinvite[subject]': {
        validators: {
          notEmpty: {
            message: 'Please enter a subject for this email'
          },
          stringLength: {
            min: 1,
            max: 120,
            message: 'The subject must be less than 120 characters'
          }
        }
      },
      ' emailinvite[message]': {
        validators: {
          notEmpty: {
            message: 'Please enter a message for this email'
          }
        }
      }
    }
  });

  submitTrigger.unbind('click').click(function (e) {
    var emailInfoText = $('#hcf-business-email-info-text');
    var emailInviteForm = $('#hcf-business-email-invite-form');
    var emailFormAddress = $('#invite_to_email');
    e.preventDefault();

    // check if selections have been made
    if (practitionerSelector.val() === '0'){
      emailInfoText.text('You must select a practitioner from the drop down list to proceed.');
      emailInfoModal.modal('show');
      return;
    }
    else if (educationalLibrarySelector.val() === '0'){
      emailInfoText.text('You must select an Educational Library from the drop down list to proceed.');
      emailInfoModal.modal('show');
      return;
    }

    emailInviteForm.formValidation('validate');

    if (!emailInviteForm[0].checkValidity || emailInviteForm[0].checkValidity()) {
      // send Email
      // check if email address was from a selection - would be in format name <a@email.com>
      var emailText = emailFormAddress.val();
      if (emailText.indexOf('<') > -1 && emailText.indexOf('>') > -1){
        emailText = str.substring(emailText.lastIndexOf("<") + 1, emailText.lastIndexOf(">"));
      }
      $.ajax({
        type: "POST", url: sendEmailUrl, data: {
          emailAddress: emailFormAddress.val(), emailSubject: $('#business_email_invite_subject').val(),
          emailText: emailText,
          emailMessage: $('#business_email_invite_message').val(), emailPractitionerSlug: currentPractitionerSlug,
          firstname: $('#invite_first_name').val(),
          lastname: $('#invite_last_name').val(),
        },
        success: function (response) {
          emailInfoText.text('Your Educational Library invite email has been sent to ' + emailFormAddress.val() + '.');
          emailFormAddress.val('');
          $('#business_email_invite_subject').val('');
          $('#business_email_invite_message').val('');
          practitionerSelector.val('0');
          educationalLibrarySelector.val('0');
          emailInfoModal.modal('show');

          // reset form


          //emailInviteFormValidator.resetForm = true;
          emailInviteForm.formValidation('resetForm', true);
        },
        error: function (xhr, status, error) {
          var i = 1 + i;
        },
        dataType: 'json'
      });
    }
  });

  $('#email_search').boot_typeahead({
    source: JSON.parse(patientList),
    display: "name",
    val: "id",
    onSelect: function (item) {
      // get first name and last name
      var nameSplit = item.value.split("~~");
      var firstName = nameSplit[0];
      var lastName = nameSplit.length > 2 ? nameSplit[1] : '';
      var email = nameSplit[nameSplit.length - 1];
      $('#invite_to_email').val(email);
      $('#invite_first_name').val(firstName);
      $('#invite_last_name').val(lastName);
    }
  });
};