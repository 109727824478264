var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function (params) {

  var displayedPopup = false;
  var offeringPageTitle = $('#hcf-page-title'),
    introVideoTrigger = $('#js-user-family-intro-video'),
    videoModalWindow = $('#hcf-core-video-modal'),
    introVideo = params.intro_video;

  introVideoTrigger.on('click', function() {
    jwplayer('sample-video').stop();
    jwplayer('offering-video').stop();
    initPlayer(introVideo);
    videoModalWindow.modal('show');
  });

  videoModalWindow.on('hidden.bs.modal', function () {
    jwplayer('intro-video').stop();
  });

  //set page title size and colour
  offeringPageTitle.css( {"color": "#15345e", "font-size": "26px", "font-weight": "bold" });

  $('[data-toggle="popover"]').popover(
      { delay: 0,
        trigger: 'manual',
        container: 'body',
        placement: 'top'
      }
  );

  $("<style id='hcfPopupClass'>")
      .prop("type", "text/css")
      .html("\
      .popover.my-popover {\
        left: 1px !important;\
        top: 1px !important;\
        width: 200px !important; \
        max-width: 200px !important; \
      }")
      .appendTo("head");

  $("<style id='hcfPopupArrowClass'>")
      .prop("type", "text/css")
      .html("\
      .popover.my-popover > .arrow {\
        top: 50% !important;\
      }")
      .appendTo("head");

  $('.hcf-offering-popup').mouseover(function() {
    var hcfOfferingPopupSettings = $(this).data('hcf-popup-settings').split(",");
    var contentTextNumber = hcfOfferingPopupSettings[0];
    var popupPlacement = hcfOfferingPopupSettings[1];
    var popupWidth = hcfOfferingPopupSettings[2];
    var $elem = $('#hcf_image_container'); // $(this);
    //var coords = this.coords.split(",");
    var leftPosition = hcfOfferingPopupSettings[3];
    var topPosition = hcfOfferingPopupSettings[4];
    var arrowPosition = hcfOfferingPopupSettings[5];
    var divForContent = $('#solutions_img_content_' + contentTextNumber);
    var contentForImage = divForContent.html();

    var styleForPopup = 'style="left: ' + leftPosition + 'px !important; top: ' + topPosition + 'px !important;"';

    $('#hcfPopupClass').text(".popover.my-popover{left:" + leftPosition + "px !important;\
        top:" + topPosition + "px !important;\
        width:" + popupWidth + "px !important; \
        max-width:" + popupWidth + "px !important; }");

    if (popupPlacement == 'bottom'){
      $('#hcfPopupArrowClass').text(".popover.my-popover > .arrow{left: 50% !important;}");
    }
    else if (popupPlacement == 'top'){
      $('#hcfPopupArrowClass').text(".popover.my-popover > .arrow{left:" + arrowPosition + "% !important; top: 100% !important}");
    }
    else {
      $('#hcfPopupArrowClass').text(".popover.my-popover > .arrow{top:" + arrowPosition + "% !important;}");
    }

    if (displayedPopup === false) {
      $elem.popover({
        placement: popupPlacement,
        trigger: 'manual',
        html: true,
        container: $elem,
        content: contentForImage,
        template: '<div ' + styleForPopup + 'class="popover my-popover" role="tooltip"><div class="arrow"></div><div class="popover-content"></div></div>'
      });
    }
    // $elem.on("shown.bs.popover", function(e){
    // });

    /*
     $elem.on('hide.bs.popover', function (e) {
     e.preventDefault();
     });
     */

    if (displayedPopup === false) {
      $elem.popover('show');
      displayedPopup = true;
    }

  })
  .mouseout(function(){
    var $elem = $('#hcf_image_container'); //$(this);
    $elem.popover('hide');
    $elem.popover('destroy');
    displayedPopup = false;
  })
  ;

};