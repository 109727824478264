/**
 * Add another widget using a data prototype
 * Snippet from http://symfony.com/doc/current/reference/forms/types/collection.html
 *
 * Parameters:
 *   prototype - the ID of the field that the data-prototype attribute is on.
 *   add_string - the text for the add new item link.
 */
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    emit = require('./eventemitter');

function addFieldForm($collectionHolder, $newLinkLi) {
  // Get the data-prototype
  var prototype = $collectionHolder.data('prototype');

  // get the new index
  var index = $collectionHolder.data('index');

  // Replace '__name__' in the prototype's HTML to
  // instead be a number based on how many items we have
  var newForm = prototype.replace(/__name__/g, index);

  // increase the index with one for the next item
  $collectionHolder.data('index', index + 1);

  var liHtml = '<li></li>';

  if ($collectionHolder.selector == "#library-videos-list"){
    liHtml = '<li class="ui-sortable-handle" data-sort-allowed="1"></li>';
  }

  // Display the form in the page in an li, before the "Add a field" link li
  var $newFormLi = $(liHtml).append(newForm);
  $newLinkLi.before($newFormLi);

  // add a delete link to the new form
  addDeleteFieldLink($newFormLi);

  $(".ckeditor").each(function () {

    if (!CKEDITOR.instances[$(this).attr("id")]){
      var currentEditor = CKEDITOR.replace( $(this).attr("id") );
    }
  });

  $('.block_type').on('change',function(){
    switch (this.value){
      case "1":
        $(this).parent().nextAll('.block_text:first').show();
        $(this).parent().nextAll('.block_video:first').hide();
        $(this).parent().nextAll('.block_quiz:first').hide();
        $(this).parent().nextAll('.block_practitioner:first').hide();
        $(this).parent().nextAll('.block_practitionertype:first').hide();
        break;
      case "2":
        $(this).parent().nextAll('.block_video:first').show();
        $(this).parent().nextAll('.block_text:first').hide();
        $(this).parent().nextAll('.block_quiz:first').hide();
        $(this).parent().nextAll('.block_practitioner:first').hide();
        $(this).parent().nextAll('.block_practitionertype:first').hide();
        break;
      case "3":
        $(this).parent().nextAll('.block_quiz:first').show();
        $(this).parent().nextAll('.block_text:first').hide();
        $(this).parent().nextAll('.block_video:first').hide();
        $(this).parent().nextAll('.block_practitioner:first').hide();
        $(this).parent().nextAll('.block_practitionertype:first').hide();
        break;
      case "4":
        $(this).parent().nextAll('.block_practitioner:first').show();
        $(this).parent().nextAll('.block_quiz:first').hide();
        $(this).parent().nextAll('.block_text:first').hide();
        $(this).parent().nextAll('.block_video:first').hide();
        $(this).parent().nextAll('.block_practitionertype:first').hide();
        break;
      case "5":
        $(this).parent().nextAll('.block_practitionertype:first').show();
        $(this).parent().nextAll('.block_practitioner:first').hide();
        $(this).parent().nextAll('.block_quiz:first').hide();
        $(this).parent().nextAll('.block_text:first').hide();
        $(this).parent().nextAll('.block_video:first').hide();
        break;
    }

  });

  $('.block_video > div > input').on('change',function(event, result){
    var currentBlockId = $(this).attr('id');
    var targetId = currentBlockId.replace('videoInput_input_id', 'video');
    $('#' + targetId).val(result.data[0] + ',' + result.data[1]);
  });

  $('.block_quiz > div > input').on('change',function(event, result){
    var currentBlockId = $(this).attr('id');
    var targetId = currentBlockId.replace('quizInput_input_id', 'quiz');
    $('#' + targetId).val(result.data[0] + ',' + result.data[1]);
  });

  $('.block_practitioner > div > input').on('change',function(event, result){
    var currentBlockId = $(this).attr('id');
    var targetId = currentBlockId.replace('practitionerInput_input_id', 'practitioner');
    $('#' + targetId).val(result.data[0] + ',' + result.data[1]);
  });

  $('.block_practitionertype > div > input').on('change',function(event, result){
    var currentBlockId = $(this).attr('id');
    var targetId = currentBlockId.replace('practitionertypeInput_input_id', 'practitionertype');
    $('#' + targetId).val(result.data[0] + ',' + result.data[1]);
  });

  if ($collectionHolder.selector == "#library-videos-list"){

    var idOfVideoInput = '#' + $newFormLi.children('div').children('div').children('input').attr('id');

    var videoChangedCheck = setInterval(function() {
      if ($(idOfVideoInput).val() != ''){
        var idOfSortOrder = idOfVideoInput.replace('video_input_id','videoSortOrder');
        $(idOfSortOrder).val(index);
        clearInterval(videoChangedCheck);
      }
    }, 100);
  }
}

function addDeleteFieldLink($fieldFormLi) {
  var $removeFormA = $('<button type="button" class="close delete-field"><span aria-hidden="true">&times;</span><span class="sr-only">remove</span></button>');

  $fieldFormLi.prepend($removeFormA);

  $removeFormA.on('click', function(e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();

    // remove the li for the field form
    $fieldFormLi.remove();
  });
}

function addSortFunctionality($fieldFormLi) {
  var $sortHtml = $('<section style="position: absolute; left: -5px;" class="col-md-1 hcf-user-educational-listing-sort-col">' +
      '<i class="fa fa-sort hcf-user-sort-arrows" aria-hidden="true" data-toggle="popover"' +
      ' data-content="Click - Hold and Drag to change the order of the video." title="Change Video Display Order"></i>' +
      '</section>');

  $fieldFormLi.prepend($sortHtml);
}

/**
 * @param collection jQuery object: Get the container that holds the collection of fields
 */
function allowNewCollectionForm(collectionHolder, addString) {

  var $collectionHolder = $(collectionHolder);

  if ($collectionHolder.hasClass('collection-holder-processed')){
    return;
  }

  $addFieldLink = $('<a href="#" class="add-item">Add +</a>');
  $addFieldLink.text(addString);

  var $newLinkLi = $('<li></li>').append($addFieldLink);

  // add a delete link to all of the existing field form li elements
  $collectionHolder.children('li').each(function() {
    if ($(this).data('sort-allowed')){
      addSortFunctionality($(this));
    }
    addDeleteFieldLink($(this));
  });
  // add the "add a field" anchor and li to the fields ul
  $collectionHolder.append($newLinkLi);

  // count the current form inputs we have (e.g. 2), use that as the new
  // index when inserting a new item (e.g. 2)
  $collectionHolder.data('index', $collectionHolder.find('li').length - 1);

  $addFieldLink.on('click', {'collectionHolder' : collectionHolder}, function(e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();

    // add a new field form (see next code block)
    addFieldForm($(e.data.collectionHolder), $newLinkLi);
    emit.emit('postAddAnother');
  });

  // If there is not data to display add a blank field
  if ($collectionHolder.children().length === 1) {
    $addFieldLink.trigger('click');
  }

  $collectionHolder.addClass('collection-holder-processed');
}

module.exports = function (params) {
  if ($.isArray(params)) {
    $.each(params, function (key, instance) {
      allowNewCollectionForm(instance.prototype, instance.add_string);
      // add ability to sort videos
      if (instance.prototype == '#library-videos-list') {
        addVideoSortOption();
      }
    });
  } else {
    allowNewCollectionForm(params.prototype, params.add_string);
    // add ability to sort videos
    if (instance.prototype == '#library-videos-list') {
      addVideoSortOption();
    }
  }
};

// setup video list with sort option
function addVideoSortOption (){
  var videoList = $("#library-videos-list");
  videoList.sortable({
    placeholder: "ui-state-highlight",
    start: function (event, ui) {
      $('.popover').popover('hide');
    },
    stop: function (event, ui) {
      var listItems = $("#library-videos-list li");
      listItems.each(function (idx, li) {
        $(li).children("div").children("input").val(idx);
      });
    }
  });
  //videoList.disableSelection();
}
