/* Add swipe functionality to Bootstrap's carousel
 *
 * In order to add the swipe functionality the carousel must be
 * initialized via Javascript and not by the [data-ride="carousel"] method.
 */
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  if (typeof params.selector === 'undefined') {
    throw new Error( "carousel-swipe is missing a selector" )
  }

  if ($.isArray(params.selector)) {
    params.selector.forEach(function (element) {
      $(element).carousel();
    });
  } else {
    $(params.selector).carousel();
  }
};