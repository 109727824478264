var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function(params) {
  var deletePatientButtons = $('.hcf-user-delete-patient-button');
  var userPatientDeleteUrl = params.patient_remove_url;

  // setup delete library buttons
  deletePatientButtons.unbind('click').click(function(event) {
    var patientTitle = $(this).data('patient-title');
    var patientId = $(this).data('patient-id');
    var deleteModal = $('#modal-confirm-patient-delete');
    var modalDeleteButton = $('#modal-confirm-patient-delete-button');
    $('#user-confirm-patient-delete-text').html(patientTitle);

    modalDeleteButton.unbind('click').click(function(event){
      // remove patien
      $.ajax({ type: "POST", url: userPatientDeleteUrl, data: {patientId: patientId},
        success: function(response){},
        dataType: 'json', async: false});

      deleteModal.modal('hide');
      location.reload(true);
    });

    deleteModal.modal('show');

  });
};


