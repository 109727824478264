var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function(params) {
  var listOfVideos = params.videos;
  var updateVideoSortOrderUrl = params.sortVideoUpdate;
  var educationalLibraryId = params.educationalLibraryId;
  var sortModal = $("#saving-educational-library-sort-order");
  var editCurrentVideo = 0;
  var editPlayers = [];
  var addVideoSearchReturnAction = $('#addVideosReturn');
  var editVideoPlayClick = $(".hcf-user-video-edit-list"),
      editVideoModal = $('#hcf-user-stats-video-modal');

  // after the user returns from a video search make sure all listed video remove
  // and play events are set properly this event is called upon hiding the
  // video search modal
  addVideoSearchReturnAction.unbind('click').click(function() {
    // reset all video row remove and play events
    editPlayers = [];
    editVideoPlayClick.unbind('click').click(function() {
      editCurrentVideo = $(this).data('video-target');
      editVideoModal.modal('show');
    });

    editVideoModal.unbind();

    editVideoModal.on('show.bs.modal', function (event) {
      educationEditStartPlayer();
    });
    editVideoModal.on('hide.bs.modal', function (event) {
      educationEditStopPlayer();
    });

    // reset all remove video buttons
    $('.hcf-user-library-remove-video').unbind('click').click(function(event) {
      var videoTitle = $(this).data('video-title');
      var videoId = $(this).data('video-id');
      var parentLi = $(this).parents('li');
      var deleteModal = $('#user-educational-delete-modal');
      var modalDeleteButton = $('#modal-confirm-delete-button');
      $('#user-confirm-delete-header').html('Delete Video From Library');
      $('#user-confirm-delete-text').html(videoTitle);

      modalDeleteButton.unbind('click').click(function(event){
        // remove video from display
        parentLi.remove();
        deleteModal.modal('hide');
      });

      deleteModal.modal('show');

    });
  });

  editVideoPlayClick.unbind('click').click(function() {
    editCurrentVideo = $(this).data('video-target');
    editVideoModal.modal('show');
  });

  editVideoModal.on('show.bs.modal', function (event) {
    educationEditStartPlayer();
  });
  editVideoModal.on('hide.bs.modal', function (event) {
    educationEditStopPlayer();
  });

  function educationEditStartPlayer() {
    $('#hcf-video-stats-player-title').html(listOfVideos[editCurrentVideo].title);
    educationEditEnsurePlayer(editCurrentVideo).on("ready", function() {
      educationEditEnsurePlayer(editCurrentVideo).play();
    });
  }

  function educationEditStopPlayer() {
    if (editCurrentVideo !== undefined) {
      educationEditEnsurePlayer(editCurrentVideo).stop();
    }
  }

  function educationEditEnsurePlayer(id) {
    if (!editPlayers[id]) {
      // TODO: Currently disabling preroll ads in modal/carousel as they
      //       don't play nice.
      if (listOfVideos.length > 0){
        if (listOfVideos[id].advertising_tag) {
          delete listOfVideos[id].advertising_tag;
        }
        editPlayers[id] = initPlayer(listOfVideos[id]);
      }
    }
    return editPlayers[id];
  }

  // setup delete library buttons
  $('.hcf-user-library-remove-video').unbind('click').click(function(event) {
    var videoTitle = $(this).data('video-title');
    var videoId = $(this).data('video-id');
    var parentLi = $(this).parents('li');
    var deleteModal = $('#user-educational-delete-modal');
    var modalDeleteButton = $('#modal-confirm-delete-button');
    $('#user-confirm-delete-header').html('Delete Video From Library');
    $('#user-confirm-delete-text').html(videoTitle);

    modalDeleteButton.unbind('click').click(function(event){
      // remove video from display
      parentLi.remove();
      $( "#user-edlibrary-form" ).submit();
      deleteModal.modal('hide');
    });

    deleteModal.modal('show');
  });

  $('[data-toggle="popover"]').popover(
    { delay: 0,
      trigger: 'hover',
      container: 'body'
    }
  );

  $( "#library-videos-list" ).sortable({
    placeholder: "ui-state-highlight",
    start: function( event, ui ) {
      $('.popover').popover('hide');
    },
    stop: function( event, ui ) {
      var newSortOrder = [];
      var listItems = $("#library-videos-list li");
      listItems.each(function(idx, li) {
        $(li).attr( "data-index", idx );
        var videoSortOrder = $("#" + $(li).children(":first").attr("id") + '_videoSortOrder');
        videoSortOrder.val(idx);
        // don't add new videos as they have not been saved to the db
        // update video sort order of new video so when it is save it is in the correct order
        if ($(li).attr( "data-video-id") != "-1"){
          newSortOrder.push({videoId: $(li).attr("data-video-id"), index: idx});
        }
      });

      // save new sort order on server
      if (newSortOrder.length > 0) {
        // show Saving Sort Order message
        sortModal.modal('show');
        $.ajax({
          type: "POST", url: updateVideoSortOrderUrl, data: {newSortOrder: newSortOrder, educationalLibraryId: educationalLibraryId},
          success: function (response) {
            // remove Saving Sort Order message
            sortModal.find('#sort-saving-description').text('The new Sort Order has been saved.');
            $('#sort-saving-description').attr('class', 'hcf-user-sort-order-saved')
          },
          error: function() {
            // remove Saving Sort Order message
            $("#saving-educational-library-sort-order").modal('hide');
          },
          dataType: 'json', async: true
        });
      }
    }
  });
  $( "#library-videos-list" ).disableSelection();
};