var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
	if ( !(typeof params.surveyCallBack === 'undefined' || params.surveyCallBack === null)){
		var surveyWindow = $('#survey_window'),
			showSurveyButton = $("#showSurveyButton"),
			surveyWidth = 0,
			surveyPath = params.surveyCallBack;
			surveySubmitUrl = params.surveySubmitUrl;
			autoDisplay = params.autoDisplay,
			cookieId = params.cookieId;
			videoId = params.videoId;
		
		function retrievedSurvey(response){
			if (response.survey_exists !== false){
				
				surveyWindow.html(response.html);
				if (surveyWindow.width() > 0)
					surveyWidth = surveyWindow.width();
				var elements = '.js-hcf-select';
				$(elements).each(function() {
					$(this).selectpicker();
				});
				
				// survey_close does not exist until the code above has completed
				$("#survey_close").click(function() {
					surveyWindow.animate({'width': 0},350, function(){ closeSurvey()});
				});
				
				$("#survey_submit").click(function() {
					if (typeof ga !== 'undefined'){
						ga('send', 'event', 'submitted', 'survey', 'video');
					}
					var formData = JSON.stringify($("#video_survey_form").serializeArray());
					
					// send data to server
					$.ajax({ type: "POST", url: surveySubmitUrl, data: formData, dataType: 'json'});
					
					//set cookie for this survey
					Cookies.set(cookieId, 'Submitted', { expires: 352 });					
					
					surveyWindow.animate({'width': 0},350, function(){ closeSurvey()});
				});
				
				surveyWindow.css('display', 'block');
				surveyWindow.css('width', 0);
				surveyWindow.animate({'width': surveyWidth},350);
				// if (!(typeof _paq === 'undefined' || _paq === null)) {
				// 	_paq.push(['trackEvent', 'Video', 'Survey View', videoId, 0, {
				// 		dimension1: 'Video Page',
				// 		dimension2: videoId
				// 	}]);
				// }
			}
			return false;
		}
		
		function closeSurvey() {
			surveyWindow.css('display', 'none');
			surveyWindow.empty();
			
			return false;
		}
		
		$(document).ready(function() {
			if ( autoDisplay === true &&
				 Cookies.get(cookieId) != 'Submitted'){
				var width = $(window).width();
				if (width > 700){
					$.ajax({ url: surveyPath, dataType: 'json', success: function(response) { setTimeout(function() { retrievedSurvey(response); }, 5000); }});
				}
			}
			else{
				showSurveyButton.click(function() {
					$.ajax({ url: surveyPath, dataType: 'json', success: function(response) { retrievedSurvey(response); } });

					return false;
				});
			}

			return false;
		}); 
	}
}