var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

/**
 * Play videos when selected/clicked from a list of video images
 */
module.exports = function (params) {
  'use strict';

  var videos = params.videos || [],
      widthOfCarouselElement = params.width_carousel_element,
      players = {},
      triggers = $('.video-play-trigger'),
      current = 0,
      numberOfVideos = videos.length;

  var titleField = $('#hcf-iframe-video-title');
  titleField.text(videos[0]['title']);

  $('.jcarousel').jcarousel({
    // Core configuration goes here
  });

  $('.jcarousel-prev').jcarouselControl({
    target: '-=1'
  });

  $('.jcarousel-next').jcarouselControl({
    target: '+=1'
  });

  // set width of carousel
  $('#previewVideoList').css('width', (numberOfVideos * widthOfCarouselElement) + 'px');

  // Play specific video from video image click
  triggers.each(function (i, el) {
    $(el).click(onVideoTriggerClick);
  });

  function startPlayer() {
    ensurePlayer(current).play();
  }

  function stopPlayer() {
    if (current !== undefined) {
      ensurePlayer(current).stop();
    }
  }

  function onVideoTriggerClick(e) {
    current = $(e.currentTarget).attr('data-index');
    startPlayer();
  }

  function ensurePlayer(id) {
    players[id] = initPlayer(videos[id]);

    // set title and description
    var titleField = $('#hcf-iframe-video-title');
    var descriptionField = $('#hcf-iframe-video-description');
    titleField.text(videos[id].title);
    descriptionField.text(videos[id].description);

    return players[id];
  }

  // initialize the first video in the player
  ensurePlayer(0);

};

