var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
	var	actionPlanIncrementUrl = params.action_plan_increment_url,
    actionPlanIncrementTrigger = $('.action-plan-increment');

  actionPlanIncrementTrigger.on('click', function(e) {
    var actionPlanSlug = $(this).data('action_plan_slug')
    
    //increment action plan view count in database
    $.ajax({
      type: "POST", url: actionPlanIncrementUrl, data: {actionPlanSlug: actionPlanSlug},
      success: function (response) {
        var i = 1+i;
      },
      error: function(xhr, status, error){
        var i = 1+i;
      },
      dataType: 'json', async: true
    });
  });

};  
