/**
* Practitioner Rating admin form
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  var	practitionerRatingSubmitUrl = params.rating_submit_url,
    practitionerSlug = params.slug,
    practitionerRatingUpdateTrigger = $('#rating-submit');
    practitionerRatingCancelTrigger = $('#rating-cancel');
    
  var selector = ".hcf-starrating";

	$(selector).each(function() {
    var ratingCaptions = { 
      1: 'Poor', 
      2: 'Okay', 
      3: 'Average', 
      4: 'Good', 
      5: 'Excellent' 
    }
    $(this).rating({size:'xs', step: 1, showClear: false, ratingClass: 'rating-fa', glyphicon: false, starCaptions: ratingCaptions });
  });
  
  $(".edit-rating").on('click',function(){
    var currentRow = $(this).closest('tr');
    var rowContents = $('td', currentRow).map(function(index, td){return $(td).text(); });
    $("#rating-information").rating('update', rowContents[2]);
    $("#rating-education").rating('update', rowContents[3]);
    $("#rating-local-services").rating('update', rowContents[4]);
    $("#rating-action-plan").rating('update', rowContents[5]);
    $("#rating-comment").val(rowContents[6]);
    $("#rating-id").text(rowContents[0]);
    
    // display rating window
    var ratingModal = $('#admin-rating-form');
    ratingModal.modal('show');
    
    return false;
  });
    
  practitionerRatingUpdateTrigger.on('click', function(e) {
    
    var ratingId = $("#rating-id").text();
    var informationRating = $("#rating-information").val();
    var educationRating = $("#rating-education").val();
    var localServiceRating = $("#rating-local-services").val();
    var actionPlanRating = $("#rating-action-plan").val();
    var comment = $("#rating-comment").val();
    
    //save rating 
    var ratingData = {
      slug: practitionerSlug,
      informationRating: informationRating,
      educationRating: educationRating,
      localServiceRating: localServiceRating,
      actionPlanRating: actionPlanRating,
      comment: comment,
      id: ratingId,
    };

    // get modal window to hide after update
    var ratingModal = $('#admin-rating-form');
    
    $.post(practitionerRatingSubmitUrl, ratingData)
      .done(function(result) {
        ratingModal.modal('hide');
        window.location.reload(true);
        return false;
      })
      .fail(function(result) {
        ratingModal.modal('hide');
        window.location.reload(true);
        return false;
      });
    
    return false;
  });

  $("#create-banner").click(function() {
    $('#hcf-banner-image').empty();
    $('#hcf-banner-container').show();
    html2canvas(document.getElementById("hcf-banner-html"), {width: 300}).then(function(canvas) {
      document.getElementById("hcf-banner-image").appendChild(canvas);
      $('#hcf-banner-container').hide();
    });
  });

};