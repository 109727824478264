var Ractive = require('ractive');

function Notifier(container, messages) {
    this._messages = messages || [];

    this._notifications = new Ractive({
        el: '#' + container,
        template: '#' + container + '-template',
        data: { notifications: this._messages }
    });
}

Notifier.prototype.send = function (type, message) {
    this.sendMultiple([{ type: type, notification: message}]);
};

Notifier.prototype.sendMultiple = function (notifications) {
    var messages = this._messages;

    notifications.map(process).forEach(function (notification) {
        messages.push(notification);
    });
    this._notifications.update('notifications');
};

function process(message) {
    var notification = { type: message.type },
        property;

    if (typeof message === 'string') {
        notification.notification = message.notification;
    }
    else {
        for (property in message) {
            notification[property] = message[property];
        }
    }

    return notification;
}

module.exports = function (container, messages) {
    return new Notifier(container, messages);
};
