var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    jwplayer = (typeof window !== "undefined" ? window['jwplayer'] : typeof global !== "undefined" ? global['jwplayer'] : null);

module.exports = function initMultipleVideoPlayers(params) {

  for (var i = 0; i < params.videos.length; i++){
    initializeEachVideo(params.videos[i]);
  }

  function initializeEachVideo(video) {
    var player = jwplayer(video.id || 'hcf-jwplayer');
    if (!player) return;

    var playing = false,
        options = {
          title: video.title,
          autostart: video.autostart || false,
          aspectratio: '16:9',
          width: '100%',
          image: video.image,
          primary: 'html5',
          ga: {"label": "title",
            "sendEnhancedEvents": false},
          sources: []
        };

    if (video.sdfile) {
      options.sources.push({
        file: video.sdfile,
        label: '360p SD'
      });
    }

    if (video.file) {
      options.sources.push({
        file: video.file,
        label: '1080p HD',
        'default': 'true'
      });
      if (!video.sdfile) {
        options.sources[0]['default'] = true;
      }
    }

    if (video.advertising_tag) {
      options.advertising = video.advertising_tag;
    }

    var playerInstance = player.setup(options);

    playerInstance.on('play',function (e) {
      var url = video.statsUrl || null;
      var recordStats = video.recordStats || null;
      //update piwik stats for video
      // if (recordStats != null) {
      //   if (!(typeof _paq === 'undefined' || _paq === null)) {
      //     var dim3Value = video.statsPractitionerTypeId != 'undefined' ? video.statsPractitionerTypeId : null;
      //     _paq.push(['trackEvent', 'Video', 'Play', video.video_id, 0, {
      //       dimension1: video.statsPage,
      //       dimension2: video.statsPageId,
      //       dimension3: dim3Value
      //     }]);
      //   }
      // }

      if (playing || !url) return;

      playing = true;

      // Request to server for stats.
      //$.get(video.statsUrl);
    });

    playerInstance.on('complete', function (e) {
      if (!playing) return;
      playing = false;
    });

    return playerInstance;
  }
};
