var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function(params) {
  var params = {};
  var editPractitionerTrigger = $('.business-admin-practitioner-edit');
  var addPractitionerTrigger = $("#business-admin-practitioner-add");

  $("#user-business-create-edit :input").change(function() {
    $("#user-business-create-edit").data("changed",true);
  });

  $("#hcf-business-practitioner-cancel").click(function() {
    $("#hcf-business-form-changed-modal").modal('hide');
  });

  editPractitionerTrigger.on('click', function() {
    var practitionerEditLink = $(this).data('edit-link');
    if ($("#user-business-create-edit").data("changed")) {
      // tell user form has changed ask to continue or not
      $("#hcf-business-practitioner-continue").unbind("click");
      $("#hcf-business-practitioner-continue").click(function() {
        window.location.href = practitionerEditLink;
      });

      $("#hcf-business-form-changed-modal").modal('show');
    }
    else {
      // no changes go to link
      $("#hcf-business-form-changed-modal").modal('hide');
      window.location.href = practitionerEditLink;
    }
  });

  addPractitionerTrigger.on('click', function() {
    var practitionerAddLink = $(this).data('add-link');
    if ($("#user-business-create-edit").data("changed")) {
      // tell user form has changed ask to continue or not
      $("#hcf-business-practitioner-continue").unbind("click");
      $("#hcf-business-practitioner-continue").click(function() {
        window.location.href = practitionerAddLink;
      });

      $("#hcf-business-form-changed-modal").modal('show');
    }
    else {
      // no changes go to link
      $("#hcf-business-form-changed-modal").modal('hide');
      window.location.href = practitionerAddLink;
    }
  });
};