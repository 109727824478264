var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer'),
    bootstrapCarouselSwipe = require('bootstrap-carousel-swipe'),
    currentPractitionerId = 0;

module.exports = function(params) {
  currentPractitionerId = params.current_practitioner_id;
  patientList = params.patient_list  || [];

};

$.fn.displayModalInfoBox = function (id, confirmAdd) {
  var modalEducationalVideoLibrary = $('#hcf-educational-video-library-modal');

  // get video library
  $.ajax({ type: "POST", url: BS.modalEducationalVideoLibrary['carouselPath'], data: {infoBoxId: id},
    success: function(response){

      modalEducationalVideoLibrary.html(response.html);
      $('#hcf-educational-video-library-modal').modal('show');

    },
    error: function(xhr, status, error){
      var i = 1+i;
      alert ('error');
    },
    dataType: 'json', async: false});
};


