var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
        jwplayer = (typeof window !== "undefined" ? window['jwplayer'] : typeof global !== "undefined" ? global['jwplayer'] : null);

module.exports = function initVideoPlayer(params) { 
  var player = jwplayer(params.id || 'hcf-jwplayer');
  var onCompleteCounter = 0;
  if (!player) return;


  var playing = false,
    options = {
      title: params.title,
      autostart: params.autostart || false,
      aspectratio: params.aspectratio || '16:9',
      width: '100%',
      image: params.image,
      primary: 'html5',
      ga: {"label": "title",
        "sendEnhancedEvents": false},
      sources: [],
      mute: true
    };

  if (params.file) {
    options.sources.push({
      file: params.file,
      label:'1080p HD',
      'default' : 'true'
    });
  }

  if (params.sdfile) {
    options.sources.push({
      file: params.sdfile,
      label: '360p SD'
    });
    if (!params.file) {
      options.sources[0]['default'] = true;
    }
  }

  if (params.advertising_tag) {
    options.advertising = params.advertising_tag;
  }

  if (typeof params.stretching !== 'undefined') {
    options.stretching = params.stretching;
  }

  var playerInstance = player.setup(options);

  if (typeof params.controls !== 'undefined') {
    player.setControls(params.controls);
  }

  playerInstance.on('play',function (e) {
    var url = params.statsUrl || null;
    var recordStats = params.recordStats || null;
    //update piwik stats for video
    // if (recordStats != null){
    //   if (!(typeof _paq === 'undefined' || _paq === null)) {
    //     var dim3Value = params.statsPractitionerTypeId != 'undefined' ? params.statsPractitionerTypeId : null;
    //     _paq.push(['trackEvent', 'Video', 'Play', params.video_id, 0, {
    //             dimension1: params.statsPage,
    //             dimension2: params.statsPageId,
    //             dimension3: dim3Value
    //     }]);
    //   }
    // }

    if (playing || !url) return;

    playing = true;

    // Request to server for stats.
    $.get(params.statsUrl);
  });

  playerInstance.on('complete', function (e) {

    if (typeof params.defaultCompleteUrl !== 'undefined') {
      if (onCompleteCounter < 1) {
        onCompleteCounter++;
        window.location.href = params.defaultCompleteUrl;
      }
    }
  });

  return playerInstance;
};
