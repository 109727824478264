var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function(params) {
  var deletePersonalVideoButtons = $('.hcf-user-remove-personal-video');
  var userDeletePersonalVideoUrl = params.deletePersonalVideoUrl;

  // setup delete library buttons
  deleteLibraryButtons.unbind('click').click(function(event) {
    var personalVideoTitle = $(this).data('video-title');
    var personalVideoId = $(this).data('video-id');
    var deleteModal = $('#user-personal-video-delete-modal');
    var modalDeleteButton = $('#modal-confirm-delete-button');
    $('#user-confirm-delete-header').html('Delete Personal Video');
    $('#user-confirm-delete-text').html(personalVideoTitle);

    modalDeleteButton.unbind('click').click(function(event){
      // remove video library
      $.ajax({ type: "POST", url: userDeletePersonalVideoUrl, data: {personalVideoId: personalVideoId},
        success: function(response){},
        dataType: 'json', async: false});

      deleteModal.modal('hide');
      location.reload(true);
    });

    deleteModal.modal('show');

  });
};