var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function(params) {
  var showiFrameDemo = $('.hcf-pract-iframe-view');
  var showiFrameCode = $(".hcf-pract-iframe-code");
  var demoModal = $('#hcf-profile-iframe-demo');
  var codeModal = $('#hcf-profile-iframe-code');

  // show demo for currently selected iFrame
  showiFrameDemo.unbind('click').click(function() {
    var demoHtml = $(this).data('iframe-demo');
    // remove any html in container and then
    // add current iFrame html
    var demoDiv = $('#hcf-pract-iframe-demo-container');
    demoDiv.empty();
    demoDiv.append(demoHtml);
    demoModal.modal('show');
    });

  demoModal.on('hide.bs.modal', function (event) {
    var demoDiv = $('#hcf-pract-iframe-demo-container');
    demoDiv.empty();
  });

  // show code for current selected iFrame
  showiFrameCode.unbind('click').click(function() {
    var iframeCode = $(this).data('iframe-code');
    // remove any html in container and then
    // add current iFrame html
    var codeTextArea = $('#hcf-pract-iframe-code');
    codeTextArea.val('');
    codeTextArea.val(iframeCode);
    codeModal.modal('show');
  });
};