$ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  var botContainer = $('#hcfBotDiv');
  var qaGraphicClosed = 'hcf_qa_open.png';
  var qaGraphicOpen = 'hcf_qa_close.png';
  var mobileHeaderText = $('#hcf-chat-small-header-text');
  var sectionName = document.body.className;
  var chatBoxClose = $('#hcf-chat-close');

  if ($(window).width() < 640) {
    qaGraphicClosed = 'qna2s.png';
    qaGraphicOpen = 'mobile_open_header.png';
  }

  var chatImageElement = $("#hcf-chat-image");
  chatImageElement.attr('src', '/images/' + qaGraphicClosed);

  chatBoxClose.click(function () {
    botContainer.hide();
  });

  botContainer.click(function () {
    var botContainer = $('#hcfBotDiv');
    var botDiv = $('#webChatBox');
    var chatImageElement = $("#hcf-chat-image");
    if (botDiv.height() == 0) {
      botContainer.addClass('qa_box_open');
      botContainer.removeClass('qa_box_closed');
      chatBoxClose.hide();

      if ($(window).width() < 640) {
        mobileHeaderText.css('display', 'block');
      }

      botDiv.height('100%');
      botDiv.append("<iframe class='qa_webchat_iframe' src='https://webchat.botframework.com/embed/HCFQABOT2?s=ri4ICGqESic.cwA.-rA.QBZ-hnXzdCmrShQv2e7RlAalK-nPzyhV7vh_zYeMyXQ'></iframe>");
      var chatImageSrc = chatImageElement.attr('src');
      var newImageSrc = chatImageSrc.replace(qaGraphicClosed, qaGraphicOpen);
      chatImageElement.attr('src', newImageSrc);

      // record to google analytics chat box open
      if (typeof ga !== 'undefined') {
        ga('send', 'event', 'chatbot', 'open');
      }
    } else {
      botContainer.removeClass('qa_box_open');
      botContainer.addClass('qa_box_closed');
      botDiv.height('0px');
      botDiv.empty();
      var chatImageSrc = chatImageElement.attr('src');
      var newImageSrc = chatImageSrc.replace(qaGraphicOpen, qaGraphicClosed);
      mobileHeaderText.css('display', 'none');
      chatImageElement.attr('src', newImageSrc);
      chatBoxClose.show();
    }
  });
}