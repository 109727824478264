/**
 * search for a video with a Drug Identification Number
 */
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initVideoPlayer = require('./jwplayer_multiple_shelfwidget_display');

module.exports = function (params) {
  var dinSearchUrl = params.dinSearchUrl,
      dinButton = $('#hcf-din-button'),
      dinInputButton = $('#hcf-din-search-button'),
      dinModalDisplay = $('#hcf-din-search-video-modal'),
      containerForVideo = $('#hcf-din-video-display'),
      dinSearchHtmlContainer = $('#hcf-din-search-html'),
      jwPlayerMainVideoId = params.mainVideoPlayer;

  // dinButton.on('click', function() {
  //   dinModalDisplay.modal('show');
  // });

/*  dinModalDisplay.on('hidden.bs.modal', function () {
    var jwPlayerDin = jwplayer('dinVideo');
    if (jwPlayerDin.length > 0)
      jwPlayerDin.stop();
    dinSearchHtmlContainer.css({display: 'block'});
    containerForVideo.html('');
    $('#hcf-din-search-number').val('');
    $('#hcf-video-din-logo').attr("src","/images/Solutions_Button_Small.png");
    $('#hcf-din-side-registration-title').html('Patient Education Centre');
    $('#hcf-din-side-registration-description').html('<a class="hcf-footer-detail-signup" href="/register/user/">Sign up</a> for Free Educational Libraries curated by our medical board.');
  });*/

  $("#hcf-din-search-number").keyup(function(event){
    if(event.keyCode == 13){
      dinInputButton.click();
    }
  });

  $('#din-indicator-block').change(function () {
    searchDinVideo();
  });

  $('#din-language-block').change(function () {
    searchDinVideo();
  });

  dinInputButton.on('click', searchDinVideo );

  function searchDinVideo () {
    // get din number to search
    var dinNumber = $('#hcf-din-search-number').val().trim();
    var dinSearchNoVideo = $('#hcf-din-search-no-video');
    var dinIndicator = $('#indicator_drop_down');
    var dinIndicatorDiv = $('#din-indicator-block');
    var dinLanguageDiv = $('#din-language-block');
    var dinLanguage = $('#din_language_drop_down');
    var searchVideo = true;
    var dinVal = 0;

    // clear error message if it was set
    dinSearchNoVideo.removeAttr('style');
    dinSearchNoVideo.css({color: 'orangered', display: 'none'});

    if (dinNumber == '02438070'){
      // check that drop down has been selected
      if (dinIndicatorDiv.css('display') == 'none' ||  dinIndicator.val() == 0){
        dinIndicatorDiv.css('display', 'block');
        $('.selectpicker[data-id="indicator_drop_down"]').css('color', 'red');
        searchVideo = false;
        dinLanguageDiv.css('display', 'none');
      }
      else{
        searchVideo = true;
        dinVal = dinIndicator.val();
      }
    }
    else if (dinNumber == '02443937' || dinNumber == '02443945'){  // jardiance din number - check which language video to display
      // check that drop down has been selected
      if (dinLanguageDiv.css('display') == 'none' ||  dinLanguage.val() == 0){
        dinIndicatorDiv.css('display', 'none');
        dinLanguageDiv.css('display', 'block');
        $('.selectpicker[data-id="din_language_drop_down"]').css('color', 'red');
        searchVideo = false;
      }
      else{
        searchVideo = true;
        dinVal = dinLanguage.val();
      }
    }
    else {
      dinIndicatorDiv.css('display', 'none');
      dinLanguageDiv.css('display', 'none');
    }

    if (searchVideo) {
      // get video if there is one connected to DIN
      $.ajax({
        type: "POST", url: dinSearchUrl, data: {dinnumber: dinNumber, dinindicator: dinVal},
        success: function (response) {
          // check if a video was found
          if (response.videoFound == true) {
            $('#hcf-din-video').css('display', 'block');
            $('#hcf-din-pdf').css('display', 'none');
            var redescaDinArray = ['02509075','02509083','02509091','02509105','02509113','02509121','02509148','02509156'];
            if (redescaDinArray.includes(dinNumber)){
              // display pdf images on right column
              $('#din-help-container').css('display', 'none');
              $('#din-pdf-container').css('display', 'block');
            }
            else{
              $('#din-pdf-container').css('display', 'none');
              $('#din-help-container').css('display', 'block');

            }
            videoPlayer = initVideoPlayer({single_video: response.single_video, videos: response.videos, shelfwidget_display: response.display_shelf_widget});
            videoPlayer.play();
          }
          else if (response.pdfFound == true){
            medicationPdf =  response.pdfFile;
            $('#hcf-din-video').css('display','none');
            pdfHtml = '<h3 style="margin-top: 10px; margin-bottom: 20px; font-weight: bold; font-size: 26px;">Drug Identification Number(DIN) Document</h3>\n' +
                '                <object width="100%" height="500" type="application/pdf" data="/pdfs/din/' + medicationPdf + '"?scrollbar=0&toolbar=0&navpanes=0">\n' +
                '                  <p>Your web browser doesn\'t have a PDF Plugin. Instead you can <a href="/pdfs/din/' + medicationPdf + '"> Click\n' +
                '                      here to download the PDF</a></p>\n' +
                '                </object>';
            $('#hcf-din-pdf').append(pdfHtml);
            $('#hcf-din-pdf').css('display', 'block');
          }
          else {
            dinSearchNoVideo.removeAttr('style');
            dinSearchNoVideo.css({color: 'orangered', display: 'block'});
            dinSearchNoVideo.html("There are no videos associated with the entered DIN #.")
          }
        },
        error: function () {
          alert("error");
        },
        dataType: 'json', async: true
      });
    }
  }
};