var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function(params) {
  var videoPlayer,
      video = params.video || [],
      registerVideoModal = $('#hcf-user-stats-video-modal'),
      userRegisterVideoTrigger = $('.hcf-user-intro-video');

  registerVideoModal.on('show.bs.modal', function (event) {
    userRegisterStartPlayer();
  });
  registerVideoModal.on('hide.bs.modal', function (event) {
    userRegisterStopPlayer();
  });

  userRegisterVideoTrigger.on('click', function() {
    registerVideoModal.modal('show');
  });

  function userRegisterStartPlayer() {
    $('#hcf-video-stats-player-title').html(video.title);
    videoPlayer = initPlayer(video);
    videoPlayer.on("ready", function() {
      videoPlayer.play();
    });
  }

  function userRegisterStopPlayer() {
    if (video !== undefined) {
      videoPlayer.stop();
    }
  }
};