var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
    
module.exports = function (params) {
  var latLng, mapElement;
  
  if ( !(typeof params.longitude === 'undefined' || params.latitude === null)){
    latLng = { lat: params.latitude , lng:params.longitude };
    mapElement = params.mapElement;
            
    $(document).ready(function() {
      var script = document.createElement("script");
      script.src = "//maps.googleapis.com/maps/api/js?key=AIzaSyC0dvv7jOrHrjPlqaYvWu2DcuE72iMP290&callback=$.drawMap";
      document.body.appendChild(script);
    });
  }
  
  $.drawMap = function() {
    if (document.getElementById(mapElement) != null) {
      var mapProp = {center: latLng, zoom: 15, mapTypeId: google.maps.MapTypeId.ROADMAP},
          map = new google.maps.Map(document.getElementById(mapElement), mapProp),
          marker = new google.maps.Marker({position: latLng, map: map});
    }
  }

  var showOnMap = $('.hcf-show-on-gmap');

  showOnMap.click (function() {
    var emailInviteModal = $('#hcf-video-library-invite');
    var lat = $(this).data('lat');
    var long = $(this).data('long');
    latLng = { lat: lat , lng:long };
    mapElement = params.mapElement;
    var mapProp = { center:latLng, zoom:15, mapTypeId:google.maps.MapTypeId.ROADMAP },
        map = new google.maps.Map(document.getElementById(mapElement),mapProp),
        marker = new google.maps.Marker({ position: latLng, map: map });
  });
};