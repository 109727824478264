var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function(params) {
  var selectImageFromHcfLibrary = $("#hcf-user-personal-video-select-image"),
  imageSearchUrl = params.imageSearchUrl,
  topicSelection = $("#upload_personal_video_primaryCategory_hierarchy-field-choices-0");

  selectImageFromHcfLibrary.unbind('click').click(function() {
    var topicId = topicSelection.val();
    // check if a topic has been chosen
    if (topicId != ''){
      $.ajax({
        type: "POST", url: imageSearchUrl, data: {topic: topicId},
        success: function (response) {
          var resultsList = $('#topic-video-placeholders-div');
          var resultsListTitle = $('#hcf-topic-video-image-title');
          resultsListTitle.text(topicSelection.find('option:selected').text() + " Video Placeholder Images");
          //var results = $.parseJSON(response);
          resultsList.html(response.html);
          $("#hcf-video-placeholder-images").modal('show');

          // set select buttons on click event - hidden field id = upload_personal_video_hcfimage
          $('.hcf-user-video-image-select-button').unbind('click').click(function(event){
            var placeholderImage = $(this).children( "input:first" ).val();
            // add image name to hidden field
            $('#upload_personal_video_hcfimage').val(placeholderImage);
            // display image - if img element exists modify src else create image element
            if ($('#hcf-personal-video-image-display').length){
              $('#hcf-personal-video-image-display').attr('src', placeholderImage);
            }
            else {
              var newImgString = "<div class='col-md-12'><span class='hcf-user-placeholder-title'>Video Placeholder Image</span></div><img id='hcf-personal-video-image-display' src="
                  + placeholderImage + " />";
              $('#hcf-user-personal-video-image-container').append(newImgString);
            }
            $("#hcf-video-placeholder-images").modal('hide');
          });
        },
        error: function () {
          alert("error");
        },
        dataType: 'json', async: true
      });
    }
    else{
      // must select topic first
      $("#user-personal-video-topic-modal").modal('show');
    }
  });
};