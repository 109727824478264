/**
* Simple scroll to techique that works using anchor tags.
*
*   ...
*   <input type="text" id="target" />
*   ...
*   <a href="#target">I will scroll to input#target</a>
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {

  $('body').on('click', '.hcf-scroll-to[href^="#"]', function(event) {
    var target = $( $(this).attr('href') );

    if( target.length ) {
      event.preventDefault();
      $('html, body').animate(
        {
          scrollTop: target.offset().top
        },
        1000,
        "swing",
        function() {
          target.focus();
        }
      );
    }
  });
};