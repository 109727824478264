/**
 * Control the submition of the video quiz form. Please be aware this script only work with one quiz.
 *
 * @param (array) quiz: questions, answers, etc about the quiz
 */


module.exports = function (params) {
  var quizArray = params.quizzes || [];
  var videoCarousel = $('#' + params.carouselid);
  var quiz_options = [];
  var quizRadioClass = 'quizclass' + 0;
  var pdfQuizContainerId = 'quiz-pdf-container' + 0;
  var currentQuiz = [];
  var pdfPath = '';
  var questions = [];
  var submit = '';
  var answers = [];
  var correct_answers = 0;

  var countAnswered = function() {
    var answered = document.querySelectorAll('.' + quizRadioClass + ' input[type="radio"]:checked');
    // Get all elements with the specified class name
    const ratingElements = document.querySelectorAll('.numeric-rating-value');
    //Get elements whose text content is not "0"
    const nonZeroRatingElements = Array.from(ratingElements).filter(element => element.textContent.trim() !== "0");

    if (questions.length === answered.length + nonZeroRatingElements.length) {
      submit.removeAttribute('disabled');
    }
    else {
      submit.setAttribute('disabled', 'disabled');
    }
  };

  var processAnswer = function(el) {
    var index = el.getAttribute('name'),
        options = document.querySelectorAll('.' + quizRadioClass + ' input[name="' + index + '"]'),
        answer = (el.value === 'true') ? true : false,
        result = document.querySelectorAll('.' + quizRadioClass + ' div.question-' + index + ' div.result'),
        explanation = document.querySelectorAll('.' + quizRadioClass + ' div[name="explanation-' + index + '"]'),
        output = 'fa-times';

    // Disable answer
    Array.prototype.forEach.call(options, function(el){
      el.setAttribute('disabled', 'disabled');
    });

    // Check answer
    if (answer == answers[index]) {
      output = 'fa-check';
      correct_answers++;
    }
    else {
      if (explanation.length > 0) {
        explanation[0].classList.remove("hide-explanation");
      }
    }

    // Print output
    result[0].innerHTML = '<i class="fa ' + output + '"></i>';
  };

  var submitForm = function(evt) {
    var answered = document.querySelectorAll('.' + quizRadioClass + ' input[type="radio"]:checked');

    Array.prototype.forEach.call(answered, function(el){
      processAnswer(el);
    });

    this.style.display = 'none';
    this.insertAdjacentHTML('afterend', '<p>You scored ' + correct_answers + ' / ' + questions.length + '</p>');
    evt.preventDefault();

    //increment video check count in database
    $.get(currentQuiz.quizCountUrl || null);

    if (currentQuiz.pdf_path){
      document.getElementById(pdfQuizContainerId).classList.remove("hide-pdf");
    }
  };

  // on initial display initialize quiz if there is one
  initializeQuiz(0);

  videoCarousel.on({"slid.bs.carousel": function () {

      // if there is a quiz initiliaze it
      var slideNumber = $('.active', videoCarousel).data('slide-to');
      quizRadioClass = 'quizclass' + slideNumber;
      pdfQuizContainerId = 'quiz-pdf-container' + slideNumber;
      initializeQuiz(slideNumber);
    }
  });

  function initializeQuiz(index) {

    if (quizArray[index].published == false){
      quiz_options = [];
    }
    else{
      quiz_options = document.querySelectorAll('.' + quizRadioClass + ' input[type="radio"]');
      currentQuiz = quizArray[index];

      if (quiz_options.length) {
        questions = currentQuiz.questions;
        submit = document.querySelector('.' + quizRadioClass + ' button[type="submit"]');
        answers = [];
        correct_answers = 0;

        for (var i = 0; i < questions.length; i++) {
          answers[i] = (questions[i].true == 1) ? true : false;
        }

        Array.prototype.forEach.call(quiz_options, function(el){
          el.addEventListener('click', countAnswered);
        });

        const numericRating = document.getElementById('numericRating');
        const numericRatingValue = document.getElementById('numericRatingValue');

        // Function to add event listener to elements with IDs starting with a specific string
        function addEventListenerToIdsStartingWith(prefix, eventType, eventHandler) {
          // Select all elements
          const allElements = document.querySelectorAll('*');

          // Loop through each element
          allElements.forEach(element => {
            // Check if the element's ID starts with the specified prefix
            if (element.id && element.id.startsWith(prefix)) {
              // Add the event listener
              element.addEventListener(eventType, eventHandler);
            }
          });
        }

        addEventListenerToIdsStartingWith('numericRating-', 'click', (e) => {
          if (e.target.classList.contains('rating-btn')) {
            const str = e.target.parentElement.id;
            const index = str.indexOf('-');
            const result = index !== -1 ? str.substring(index + 1) : '';
            const ratingElement = document.getElementById('numericRatingValue-' + result);
            const value = e.target.dataset.value;
            ratingElement.textContent = value;
            document.querySelectorAll('#numericRating-' + result  + ' .rating-btn').forEach(btn => {
              btn.classList.toggle('active', btn.dataset.value <= value);
            });
          }

          //Preventing page refresh
          e.preventDefault();
          countAnswered();
        });

        submit.addEventListener('click', submitForm);
      }
    }
  }

};
