var AutoComplete = require('./autocomplete');
$ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function(params) {
  ac = new AutoComplete(params.url, params.selectorId, params.type);
  ac.attach();
};

$(document).ready(function() {
  // set search placeholder depending on screen size
  var siteSearchInputBox = $('#site-search');
  var search_placeholder = 'Search Practitioners, Videos, Businesses';
  if ($(window).width() < 1135){
    search_placeholder = 'Search Health A-Z';
  }

  // set placeholder value
  siteSearchInputBox.attr('placeholder', search_placeholder);
});
