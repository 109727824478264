var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function(params) {
  var deleteQuestionButton = $('#hcf-advisor-question-delete'),
    deleteQuestionUrl = params.deleteQuestionUrl,
    allowedToEdit = params.allowedToEdit,
    allowedToDelete = params.allowedToDelete,
    deleteSuccessUrl = params.deleteSuccessReturnUrl,
    topicChange = $('#question_answer_advisor_topic_hierarchy-field-choices-0'),
    subTopicChange = $('#question_answer_advisor_topic_hierarchy-field-choices-1'),
    searchQuestionsByTopicUrl = params.searchQuestionsByTopicUrl;


  if (!allowedToEdit){
    $('#question_answer_advisor_save').prop('disabled', true);
  }

  if (!allowedToDelete){
    deleteQuestionButton.prop('disabled', true);
  }

  topicChange.change(function () {
    if ($(this).val() != null && $(this).val() !== "") {
      retrieveQuestionsByTopic($(this).val());
    }
  });

  subTopicChange.change(function () {
    if ($(this).val() != null && $(this).val() !== "") {
      retrieveQuestionsByTopic($(this).val());
    }
  });

  function retrieveQuestionsByTopic(id){
    $.ajax({ type: "POST", url: searchQuestionsByTopicUrl, data: {topicId:  id},
      success: function(response){
        var resultsList = $('#hcf-user-practitioner-question-div');
        resultsList.html(response.html);
      },
      dataType: 'json'});
  }

  // setup delete library buttons
  deleteQuestionButton.unbind('click').click(function(event) {
    var questionTitle = $('#question_answer_advisor_question').val();
    var questionId = $(this).data('question-id');
    var deleteModal = $('#user-educational-delete-modal');
    var modalDeleteButton = $('#modal-confirm-delete-button');
    $('#user-confirm-delete-header').html('Delete Current Question?');
    $('#user-confirm-delete-text').html('"' + questionTitle + '" ?');

    if (questionId !== 0) {
      modalDeleteButton.unbind('click').click(function (event) {
        // remove video library
        $.ajax({
          type: "POST", url: deleteQuestionUrl, data: {questionId: questionId},
          success: function (response) {
          },
          dataType: 'json', async: false
        });

        deleteModal.modal('hide');
        window.location = deleteSuccessUrl;
      });

      deleteModal.modal('show');
    }
  });
};