/**
* Comment form JS.
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  var comment = $('.hcf-comment'),
      commentFormWrapper = $('.hcf-comment-form-wrapper'),
      commentForm = $('.hcf-comment-form'),
      commentFormBody = $('.hcf-comment-form-body'),
      commentFormUserInfo = $('.hcf-comment-form-user-info'),
      commentReplyLink = $('.hcf-comment-reply a');

  // Initial state
  commentFormWrapper.addClass('collapsed');

  // Set parent if you click reply.
  commentReplyLink.click(function(event) {
    event.preventDefault();

    var id = $(this).data('id');
    $('select[name="comment[parent]"]', commentForm).val(id);

    commentFormBody.focus();
  });

  // Toggle comment flag when hovering over comment.
  // comment.hover(function() {
  //   $(this).children('.hcf-comment-flag').toggleClass('visible');
  // });

  commentFormBody.attr('rows', 1);

  // Toggle comment form user fields.
  commentFormBody.focus(function() {
    var $wrapper = $(this).parents('.hcf-comment-form-wrapper'),
        $form = $(this).parents('.hcf-comment-form'),
        $collapse = $form.find('.collapse');

    $(this).attr('rows', 5);
    $wrapper.removeClass('collapsed');
    $wrapper.addClass('expanded');
    $collapse.addClass('in');
  });

  commentFormBody.blur(function() {
    if ($(this).val() === '') {
      var $wrapper = $(this).parents('.hcf-comment-form-wrapper'),
          $form = $(this).parents('.hcf-comment-form'),
          $collapse = $form.find('.collapse');

      // set rows to 1.
      $(this).attr('rows', 1);
      // Add collapsed class to parent.
      $wrapper.removeClass('expanded');
      $wrapper.addClass('collapsed');
      // Hide user controls.
      $collapse.removeClass('in');
    }
  });

  commentFormWrapper.click(function(event) {
    event.stopPropagation();
  });

  // Flag popover
  $('[data-toggle="popover"]').popover();
  $('body').on('click', function() {
    $('.hcf-comment-action-form .action-cancel').click(function() {
      $(this).parents('.popover:first').popover('hide');
    });
  });
};
