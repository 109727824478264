var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
	var registerEmailTextBox = $('#newsletter_email_textbox'),
		registerEmail_SignUpButton = $('#newsletter_email_button'),
		registrationPostUrl = params.registrationPostUrl,
		currentURL = params.urlRegisteringFrom,
		emailResponseText = $('#emailResponseText');

	registerEmail_SignUpButton.click(function (event){
		// test email and update database with new email if valid
		var emailAddress = registerEmailTextBox.val();
		if (!isValidEmailAddress(emailAddress)){
			var errorText = "Please enter a valid email address.";
			emailResponseText.text(errorText);
			emailResponseText.removeClass( "hcf-email-registration-response-off hcf-email-registration-response-email-invalid" ).addClass( "hcf-email-registration-response-email-invalid" );
		}
		else {
			if (typeof ga !== 'undefined'){
				ga('send', 'event', 'registration', 'newsletter', currentURL);
			}
			// send email to server
			$.ajax({ type: "POST", url: registrationPostUrl, data: {email: emailAddress, url: currentURL}});
					
			// show thank you
			var thankYouText = "Thank you for joining our newsletter!";
			emailResponseText.text(thankYouText);
			emailResponseText.removeClass( "hcf-email-registration-response-off hcf-email-registration-response-email-invalid" ).addClass( "hcf-email-registration-response-display" );
		}
		
		return false;
	});
	
	registerEmailTextBox.keyup(function(event){
		if(event.keyCode == 13){
			registerEmail_SignUpButton.click();
		}
		
		return false;
	});
	
	function isValidEmailAddress(emailAddress) {
		var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
		return pattern.test(emailAddress);
	};
}