var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
	var	likeSubmitUrl = params.like_click_url,
    businessLikeSlug = params.slug,
    likeTrigger = $('.business-like');
    
  likeTrigger.on('click', function(e) {
    if (typeof ga !== 'undefined'){
      ga('send', 'event', 'business-like', businessLikeSlug);
    }
    
    e.preventDefault();
    
    //increment like count in database
    $.ajax({type: "GET", url: likeSubmitUrl, success: function(response){redrawPage();}, error: function(xhr, status, error) {myerror(xhr, status, error);}});
    
    return false;
  });
  
  function myerror(xhr, status, error){
  }
  
  // reload window to update count on screen and get another page view in analytics
  function redrawPage(){
    window.location.reload(true);
  }
};  
