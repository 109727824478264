var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    emit = require('./eventemitter');

$.fn.hierarchyChain = function (context) {

  var bindChildVisibility = function(e) {
    if (!$(this).val()) {
      $(e.data.select).parent().hide();
    } else {
      // Only show the select list if it's not blank.
      if ($('option', $(e.data.select)).length > 1) {
        $(e.data.select).parent().show();
      } else {
        $(e.data.select).parent().hide();
      }
    }
  };

  var i = 1;

  while ($('select.hierarchy-field-choices-lvl-' + i, this).length) {
    // chain to parent.
    var parent = $('select.hierarchy-field-choices-lvl-' + (i - 1), this),
        select_box = $('select.hierarchy-field-choices-lvl-' + i, this);

    // Limit the select box options to children of the parent.
    $(select_box).chained(parent);
    // Hide the select if the parent is blank or if the select field is blank.
    $(parent).change({select: select_box}, bindChildVisibility);

    // Trigger event manually the first time to initialize the visbility
    // states.
    $(parent).trigger('change');
    i++;
  }

  $(this).addClass('hierarchy-processed');
};

module.exports = function(params) {
    var optional_list = params.optional;
    $(params.primary).hierarchyChain();
    $(optional_list).each(function(){
        $(this).hierarchyChain();
    });
    emit.on('postAddAnother', function() {
        $(optional_list).each(function(){
            $(this).hierarchyChain();
        });
    });
};
