module.exports = function (params) {
  var images = [
    '/images/home_banners/home_page_health_workers_01.png',
    '/images/home_banners/home_page_health_workers_02.png',
    '/images/home_banners/home_page_health_workers_03.png',
    '/images/home_banners/home_page_health_workers_04.png',
    '/images/home_banners/home_page_health_workers_05.png'
  ];

  var banner = document.getElementById("home-banner");

  if (banner) {
    var min = 0,
        max = images.length - 1;

    //Returns a random integer between min (inclusive) and max (inclusive)
    var i = Math.floor(Math.random() * (max - min + 1)) + min;

    banner.style.backgroundImage = "url(" + images[i] + ")";
  }
};