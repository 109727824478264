var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer'),
    bootstrapCarouselSwipe = require('bootstrap-carousel-swipe'),
    currentPractitionerId = 0;

module.exports = function(params) {
  currentPractitionerId = params.current_practitioner_id;
  patientList = params.patient_list  || [];

  // if the page was opened from an email invite - url had email_invite data
  if (params.email_invited == true){
    $(document).ready(function() {
      // stop current player if it is playing on practitioner page
      if($('#featuredvideo').length){
        if (BS.jwplayer){
          if (BS.jwplayer.advertising_tag) {
            delete BS.jwplayer.advertising_tag;
          }
          BS.jwplayer.autostart = false;
          initPlayer(BS.jwplayer);
        }
      }

      // show login or signup modal
      $('#hcf-educational-video-login-modal').modal('show');
    });
  }

  if (patientList.length > 0){
    $('#email_search').boot_typeahead({
      source: JSON.parse(patientList),
      display: "name",
      val: "id",
      onSelect: function (item) {
        // get first name and last name
        var nameSplit = item.value.split("~~");
        var firstName = nameSplit[0];
        var lastName = nameSplit.length > 2 ? nameSplit[1] : '';
        var email = nameSplit[nameSplit.length - 1];
        $('#invite_to_email').val(email);
        $('#invite_first_name').val(firstName);
        $('#invite_last_name').val(lastName);
      }
    });
  }

  if (params.showEmailInviteButton == true){
    var emailInviteSend = $('#educational-library-invite');

    emailInviteSend.click (function(){
      var emailInviteModal = $('#hcf-video-library-invite');
      var emailInviteForm = $("#hcf-email-invite-form");

      // validate fields
      if (emailInviteForm[0].checkValidity()) {
        // if (!(typeof _paq === 'undefined' || _paq === null)) {
        //   _paq.push(['trackEvent', 'Practitioner', 'Email Invite', currentPractitionerId, 0, {
        //     dimension1: 'Practitioner Page',
        //     dimension2: currentPractitionerId
        //   }]);
        // }

        // library invite
        $.ajax({
          type: "POST", url: BS.modalEducationalVideoLibrary['emailInvitePath'],
          data: {
            name: $('#invite_name').val(),
            firstname: $('#invite_first_name').val(),
            lastname: $('#invite_last_name').val(),
            emailto: $('#invite_to_email').val(),
            subject: $('#invite_subject').val(),
            message: $('#invite_message').val()
          },
          success: function (response) {
            var i = 1+i;
          },
          error: function(xhr, status, error){
            var i = 1+i;
          },
          dataType: 'json', async: false
        });

        emailInviteModal.modal('hide');
      }
    });
  }

  if (params.auto_start_ed_library == true){
    $.fn.getEducationalLibrary(params.auto_start_ed_library_id, false);
  }

  $('#hcf-video-library-invite').on('hide.bs.modal', function () {
    // reset form
    var emailData = BS.modalEducationalVideoLibrary.emailInviteData;
    var emailInviteForm = $('hcf-email-invite-form');

    //emailInviteFormValidator.resetForm = true;
    $('#hcf-email-invite-form').formValidation('resetForm', true);

    // set default text for email
    $('#invite_first_name').val('');
    $('#invite_last_name').val('');
    $('#invite_name').val(emailData.email_invite_name);
    $('#invite_subject').val(emailData.email_invite_subject);
    $('#invite_message').val(emailData.email_invite_message);

    emailInviteForm.trigger("reset");
  });

  // var emailInviteFormValidator = $('#hcf-email-invite-form').formValidation({
  //   framework: 'bootstrap',
  //   icon: {
  //     valid: 'fa fa-check',
  //     invalid: 'fa fa-times',
  //     validating: 'fa fa-refresh'
  //   },
  //   fields: {
  //     'emailinvite[name]': {
  //       validators: {
  //         notEmpty: {
  //           message: 'The first name is required'
  //         },
  //         stringLength: {
  //           min: 1,
  //           max: 120,
  //           message: 'The first name must be less than 120 characters'
  //         }
  //       }
  //     },
  //     'emailinvite[firstName]': {
  //       validators: {
  //         notEmpty: {
  //           message: 'The first name is required'
  //         },
  //         stringLength: {
  //           min: 1,
  //           max: 120,
  //           message: 'The first name must be less than 120 characters'
  //         }
  //       }
  //     },
  //     'emailinvite[lastName]': {
  //       validators: {
  //         notEmpty: {
  //           message: 'The last name is required'
  //         },
  //         stringLength: {
  //           min: 1,
  //           max: 120,
  //           message: 'The last name must be less than 120 characters'
  //         }
  //       }
  //     },
  //     'emailinvite[email]': {
  //       validators: {
  //         notEmpty: {
  //           message: 'The email address is required'
  //         },
  //         emailAddress: {
  //           message: 'This is not a valid email address'
  //         }
  //       }
  //     },
  //     'emailinvite[subject]': {
  //       validators: {
  //         notEmpty: {
  //           message: 'Please enter a subject for this email'
  //         },
  //         stringLength: {
  //           min: 1,
  //           max: 120,
  //           message: 'The subject must be less than 120 characters'
  //         }
  //       }
  //     },
  //     ' emailinvite[message]': {
  //       validators: {
  //         notEmpty: {
  //           message: 'Please enter a message for this email'
  //         }
  //       }
  //     }
  //   }
  // });
};

$.fn.displayEmailInvite = function () {
  var emailInviteModal = $('#hcf-video-library-invite');
  var emailData = BS.modalEducationalVideoLibrary.emailInviteData;
  var emailElement = $('#invite_to_email');
  var currentTextTitle = '[title]';
  var currentTitle = $('#email-invite-library-select').find("option:selected").text();

  emailData.email_invite_message = emailData.email_invite_message.replace(currentTextTitle, currentTitle);
  currentTextTitle = currentTitle;

  // set default text for email
  $('#invite_name').val(emailData.email_invite_name);
  $('#invite_subject').val(emailData.email_invite_subject);
  $('#invite_message').val(emailData.email_invite_message);
  if (emailElement.is("input")){
    emailElement.val('');
  }


  // setup action on change of educational library
  $('#email-invite-library-select').on('change', function () {
    var selected = $(this).find("option:selected").val();
    var currentTitle = $(this).find("option:selected").text();

    $('#invite_message').val($('#invite_message').val().replace(/edlib=\d+/,'edlib=' + selected));
    $('#invite_message').val($('#invite_message').val().replace(currentTextTitle, currentTitle));
    currentTextTitle = currentTitle;
  });

  emailInviteModal.modal('show');
};

$.fn.getEducationalLibrary = function (id, confirmAdd) {
  var modalEducationalVideoLibrary = $('#hcf-educational-video-library-modal');

  if (confirmAdd == 'true'){
    //setup library add confirmation
    var videoLibraryConfirmAddDelete = $('#educational-library-confirm-remove');

    // check if click event exists - remove current event before adding new one with proper id
    // for current remove request
    var removeButtonEvents = $._data(document.getElementById("educational-library-confirm-remove"), 'events');
    if (removeButtonEvents && removeButtonEvents.click){
      videoLibraryConfirmAddDelete.off("click");
    }

    videoLibraryConfirmAddDelete.click({libraryid: id}, function(event){
      // remove video library
      $.ajax({ type: "POST", url: BS.modalEducationalVideoLibrary['removeLibraryPath'], data: {educationalVideoLibraryId: event.data.libraryid},
        success: function(response){},
        dataType: 'json', async: false});

      $('#hcf-video-library-confirm').modal('hide');
    });
  }

  // get video library
  $.ajax({ type: "POST", url: BS.modalEducationalVideoLibrary['carouselPath'], data: {educationalVideoLibraryId: id,
      currentPractitionerId: currentPractitionerId},
    success: function(response){
      $.fn.setupCarousel(response.videos, response.html, response.params, response.confirm_add);},
    dataType: 'json', async: false});
};

$.fn.setupCarousel = function (currentVideos, carouselHtml, params, confirmAdd){
  var modalEducationalVideoLibrary = $('#hcf-educational-video-library-modal');
  modalEducationalVideoLibrary.html(carouselHtml);

  var current,
      videos = currentVideos || [],
      players = {},
      modal = $('#hcf-video-carousel-modal'),
      triggers = $('a[href^="#slide"]'),
      videoCarousel = $('#hcf-educational-library-video-carousel');
  modal.attr('data-confirm', confirmAdd);

  // stop current player if it is playing on practitioner page
  if($('#featuredvideo').length){
    if (BS.jwplayer){
      if (BS.jwplayer.advertising_tag) {
        delete BS.jwplayer.advertising_tag;
      }
      BS.jwplayer.autostart = false;
      initPlayer(BS.jwplayer);
    }
  }

  // if there are no videos then we are displaying the login modal window
  if (videos.length > 0){
    modal.on('show.bs.modal', startPlayer);
    modal.on('hide.bs.modal', function (event) {
      stopPlayer();
    });
    modal.on('hidden.bs.modal', function (event) {
      var confirmModal = $(event.currentTarget);
      confirmLibraryAdd (confirmModal.data('confirm'));
    });

    videoCarousel.on({
      "slide.bs.carousel": stopPlayer,
      "slid.bs.carousel": function () {
        startPlayer();
        // Carousel cycles automatically after changing slides
        // so we have to pause it again after each slide change.
        videoCarousel.carousel('pause');
      }
    });

    // Open correct video slide when clicking video teaser link
    triggers.each(function (i, el) {
      $(el)
          .addClass('js-video-carousel-trigger')
          .attr('data-toggle', 'modal')
          .attr('data-target', '#hcf-video-carousel-modal')
          .attr('data-slide-to', $(el).attr('href').split('-').pop())
          // .attr('href', '#')
          .click(onTriggerClick);
    });

    $('#hcf-video-carousel-modal').modal('show');
  }
  else {
    $('#hcf-educational-video-login-modal').modal('show');
  }

  function startPlayer() {
    current = $('.active', videoCarousel).data('slide-to');
    ensurePlayer(current).on("ready", function() {
      ensurePlayer(current).play();
    });
  }

  function confirmLibraryAdd (confirmAdd){
    if (confirmAdd == true){
      $('#hcf-video-library-confirm').modal('show');
    }
  }

  function stopPlayer() {
    if (current !== undefined) {
      ensurePlayer(current).stop();
    }
  }

  function onTriggerClick(e) {
    // Initialize carousel - not automatically animated due to 'data-interval="false"'.
    videoCarousel.carousel($(e.currentTarget).data('slide-to'));
    e.preventDefault();
  }

  function ensurePlayer(id) {
    if (!players[id]) {
      // TODO: Currently disabling preroll ads in modal/carousel as they
      //       don't play nice.
      if (videos.length > 0){
        if (videos[id].advertising_tag) {
          delete videos[id].advertising_tag;
        }
        players[id] = initPlayer(videos[id]);
      }
    }
    return players[id];
  }
};

$.fn.getEducationalLibraryActionPlan = function (id) {
  var modalEducationalVideoLibrary = $('#hcf-educational-video-library-modal');

  // get video library
  $.ajax({ type: "POST", url: BS.modalEducationalVideoLibrary['actionPlanPath'], data: {educationalVideoLibraryId: id},
    success: function(response){
      $.fn.setupActionPlan(response.html);
      $('#hcf-educational-video-library-actionplan-modal').modal('show');},
    dataType: 'json', async: false});
};

$.fn.removeEducationalVideoLibrary = function (id, title) {
  var educationalLibraryTitle = $('#video-library-title');
  var videoLibraryDelete = $('#video-library-delete');

  educationalLibraryTitle.html(title);

  // check if click event exists - remove current event before adding new one with proper id
  // for current remove request
  var deleteButtonEvents = $._data(document.getElementById("video-library-delete"), 'events');
  if (deleteButtonEvents && deleteButtonEvents.click){
    videoLibraryDelete.off("click");
  }

  videoLibraryDelete.click({libraryid: id}, function(event){
    // remove video library
    $.ajax({ type: "POST", url: BS.modalEducationalVideoLibrary['removeLibraryPath'], data: {educationalVideoLibraryId: event.data.libraryid},
      success: function(response){},
      dataType: 'json', async: false});

    $('#hcf-educational-video-library-delete-modal').modal('hide');
    location.reload(true);
  });

  $('#hcf-educational-video-library-delete-modal').modal('show');
};

$.fn.setupActionPlan = function (actionPlanHtml) {
  var modalEducationalVideoLibraryActionPlan = $('#hcf-educational-video-library-actionplan-container');
  modalEducationalVideoLibraryActionPlan.html(actionPlanHtml);

  var printModal = function() {
    var target = $(this).attr('data-target'),
        hiddenPrint = $('body').children(':not(script):not(.hidden-print)').addClass('hidden-print');

    $('body').append('<div id="print-modal" class="visible-print-block">' + $(target).html() + '</div>');
    window.print();
    hiddenPrint.removeClass('hidden-print');
    $('#print-modal').remove();
  };

  $( ".modal" ).on( "click", ".btn-print", printModal);
};

$.fn.addEducationalVideoLibrary = function (id, title) {

  $.ajax({ type: "POST", url: BS.modalEducationalVideoLibrary['addLibraryPath'], data: {videoLibraryId:  id},
    success: function(response){
      var addedLibraryMessage = response.added_library_message;
      $('#user-add-video-library-message').html(addedLibraryMessage);
      $('#modal-add-video-library').modal('show');
    },
    dataType: 'json'});
};
