$ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
/*
 * Redirect users to the proper Topic page
 */
module.exports = function (params) {
  var relatedTopicSelect = $('#related-topic-select');
  relatedTopicSelect.on('change', function () {
    var url = $(this).find("option:selected").val();
    window.location.href = url;
  });
};