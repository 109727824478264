/**
 * sets the modal practitioner page stats drilldown for selected vide0
 */
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  var dataTrigger = $('.user-modal-pract-page');
  var videosArray = params.videos;

  dataTrigger.on('click', function() {
    currentTarget = $(this).attr('modal-target');

    // get data to display
    currentVideoId = $(this).data('video');
    var videoData = videosArray.filter(function(video){return video['video_id'] == this}, currentVideoId);

    $('#hcf-user-video-pract-page-title').html(videoData[0].title);
    // get proper sized image from video list
    var videoImage = $('#video-image' + videoData[0].video_id).attr('src');
    $('#hcf-user-video-pract-page-image').attr('src',videoImage);
    $('#hcf-user-video-pract-page-total').html(videoData[0].practitionerPageViews);
    $('#hcf-user-video-pract-page-total').hcfDigitFormat();

    // add all of the practitioner types and counts to modal window
    // <li>
    //    <div class="col-md-6 hcf-padding-left-none-md-only">
    //        <p class="hcf-stat-pract-page-breakdown-page-title">{{ item.practitionerType }}</p>
    //        <p class="hcf-stat-pract-page-breakdown-page-count hcf-pract-type-count">{{ item.views|number_format }}</p>
    //    </div>
    // </li>

    $('#hcf-user-pract-page-breakdown').empty();
    $.each(videoData[0].practitionerTypeStats, function(key, value){
      var listString = '<li><div class="col-md-6 hcf-padding-left-none-md-only"><p class="hcf-stat-pract-page-breakdown-page-title">' + value.name +
              '</p><p class="hcf-stat-pract-page-breakdown-page-count hcf-pract-type-count">' + value.count + '</p></div></li>';
      $('#hcf-user-pract-page-breakdown').append(listString);
    });
    $('.hcf-pract-type-count').hcfDigitFormat();
    $('#' + currentTarget).modal('show');
  });
};

$.fn.hcfDigitFormat = function(){
  return this.each(function(){
    $(this).text( $(this).text().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") );
  })
};