var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    config = window.BS = window.BS || {},
    messengers = require('./messenger')(config.messages || {}),
    services = $.extend({}, messengers),
    app = require('./initializer')(config, services),
    selectpicker = require('./selectpicker'),
    readmore = require('./readmore'),
    revealer = require('./revealer'),
    listcols = require('./listcols'),
    comments = require('./comments'),
    addanother = require('./addanother'),
    autocomplete_form = require('./autocomplete_form'),
    autocomplete_site = require('./autocomplete_site'),
    autocomplete_location = require('./autocomplete_location'),
    autocomplete = require('./autocomplete'),
    statesorprovinces = require('./statesorprovinces'),
    equalHeight = require('./equal-height.js'),
    modalCarouselVideo = require('./modal-carousel-video'),
    characterCounter = require('./character-counter'),
    location = require('./location'),
    hierarchyField = require('./hierarchy-field'),
    videoQuiz = require('./video-quiz'),
    modalPrint = require('./modal-print'),
    homeBanner = require('./home-banner'),
    pracTypeRedirect = require('./practitioner-type-redirect'),
    carouselSwipe = require('./carousel-swipe'),
    analytics = require('./analytics'),
    scrollTo = require('./scroll-to'),
    video_survey = require('./video-survey'),
    newsletter_registration = require('./newsletter-registration'),
    user_topics = require('./user_topics'),
    modalEducationalVideoLibrary = require('./modal-educational-video-library'),
    hcf_google_map = require('./hcf-google-map'),
    practitioner_data_display = require('./practitioner-data-display'),
    practitioner_like = require('./practitioner-like'),
    practitioner_share = require('./practitioner-share'),
    business_like = require('./business-like'),
    business_refer = require('./business-refer'),
    claimable_practitioners = require('./claimable_practitioners'),
    practitioner_rating = require('./practitioner-rating'),
    practitioner_admin = require('./practitioner-admin'),
    pharma_offering = require('./pharma-offering'),
    offering_hospital_videos = require('./offering_hospital_videos'),
    user_profile_display = require('./user-profile-display'),
    user_profile_account = require('./user-profile-account'),
    user_profile_edit_educational_library = require('./user-profile-edit-educational-library'),
    user_profile_educational_library = require('./user-profile-educational-library'),
    autocomplete_user_video_search = require('./autocomplete_user_video_search'),
    iframe_video = require('./iframe_video'),
    cme_education = require('./cme-education'),
    user_profile_video_pract_page_stats = require('./user-profile-video-pract-page-stats'),
    jwplayer_playlist = require('./jwplayer_playlist'),
    jwplayer_multiple_videos = require('./jwplayer_multiple_videos'),
    jwplayer_multiple_sample_videos = require('./jwplayer_multiple_sample_videos'),
    jwplayer_iframe_related = require('./jwplayer_iframe_related'),
    user_profile_iframe = require('./user-profile-iframe'),
    user_profile_personal_video_list = require('./user-profile-personal-video-list'),
    personal_video_upload = require('./personal_video_upload'),
    din_search_video = require('./din_search_video'),
    user_home_page = require('./user_home_page'),
    offering_popup = require('./offering_popup'),
    user_topic_interests = require('./user_topic_interests'),
    user_registration = require('./user_registration'),
    user_business_registration = require('./user_business_registration'),
    user_business_create_edit = require('./user_business_create_edit'),
    user_business_practitioner_create_edit = require('./user_business_practitioner_create_edit'),
    user_business_send_email = require('./user_business_send_email'),
    video_section = require('./video_section'),
    user_practitioner_advisor = require('./user_practitioner_advisor'),
    user_profile_patient = require('./user_profile_patient'),
    related_topic_redirect = require('./related_topic_redirect'),
    msoft_question_bot = require('./msoft_question_bot'),
    increment_counters = require('./increment_counters'),
    modal_info_box_display = require('./modal_info_box_display'),
    info_box_carousel = require('./info-box-carousel'),
    jwplayer_multiple_shelfwidget_display = require('./jwplayer_multiple_shelfwidget_display'),
    videoboxx_video_quiz = require('./videoboxx-video-quiz'),
    jwplayer = require('./jwplayer'); // << Be aware, do not duplicated this semicolon or you'll create a global object.

app.register('selectpicker', selectpicker);
app.register('readmore', readmore);
app.register('revealer', revealer);
app.register('listcols', listcols);
app.register('location', location);
app.register('comments', comments);
app.register('addanother', addanother);
app.register('autocomplete_form', autocomplete_form);
app.register('autocomplete_site', autocomplete_site);
app.register('autocomplete_location', autocomplete_location);
app.register('statesorprovinces', statesorprovinces);
app.register('equalHeight', equalHeight);
app.register('modalCarouselVideo', modalCarouselVideo);
app.register('characterCounter', characterCounter);
app.register('jwplayer', jwplayer);
app.register('hierarchyField', hierarchyField);
app.register('videoQuiz', videoQuiz);
app.register('modalPrint', modalPrint);
app.register('homeBanner', homeBanner);
app.register('pracTypeRedirect', pracTypeRedirect);
app.register('carouselSwipe', carouselSwipe);
app.register('track-404', require('./track-404'));
app.register('contact', require('./contact'));
app.register('scrollTo', scrollTo);
app.register('video_survey', video_survey);
app.register('hcf_google_map', hcf_google_map);
app.register('claimable_practitioners', claimable_practitioners);
app.register('newsletter_registration', newsletter_registration);
app.register('user_topics', user_topics);
app.register('practitioner_data_display', practitioner_data_display);
app.register('practitioner-like', practitioner_like);
app.register('practitioner-share', practitioner_share);
app.register('business-like', business_like);
app.register('business-refer', business_refer);
app.register('practitioner_rating', practitioner_rating);
app.register('practitioner_admin', practitioner_admin);
app.register('pharma_offering', pharma_offering);
app.register('offering_hospital_videos', offering_hospital_videos);
app.register('modalEducationalVideoLibrary', modalEducationalVideoLibrary);
app.register('user_profile_display', user_profile_display);
app.register('user_profile_account', user_profile_account);
app.register('autocomplete_user_video_search', autocomplete_user_video_search);
app.register('user_profile_edit_educational_library', user_profile_edit_educational_library);
app.register('user_profile_educational_library', user_profile_educational_library);
app.register('iframe_video', iframe_video);
app.register('cme_education', cme_education);
app.register('user_profile_video_pract_page_stats', user_profile_video_pract_page_stats);
app.register('jwplayer_playlist', jwplayer_playlist);
app.register('jwplayer_iframe_related', jwplayer_iframe_related);
app.register('jwplayer_multiple_videos', jwplayer_multiple_videos);
app.register('jwplayer_multiple_sample_videos', jwplayer_multiple_sample_videos);
app.register('user_profile_iframe', user_profile_iframe);
app.register('user_profile_personal_video_list', user_profile_personal_video_list);
app.register('personal_video_upload', personal_video_upload);
app.register('din_search_video', din_search_video);
app.register('offering_popup', offering_popup);
app.register('user_topic_interests', user_topic_interests);
app.register('user_registration', user_registration);
app.register('user_business_registration', user_business_registration);
app.register('user_business_create_edit', user_business_create_edit);
app.register('user_business_practitioner_create_edit', user_business_practitioner_create_edit);
app.register('user_business_send_email', user_business_send_email);
app.register('video_section', video_section);
app.register('user_practitioner_advisor', user_practitioner_advisor);
app.register('user_profile_patient', user_profile_patient);
app.register('user_home_page', user_home_page);
app.register('msoft_question_bot', msoft_question_bot);
app.register('increment_counters', increment_counters);
app.register('modal_info_box_display', modal_info_box_display);
app.register('info_box_carousel', info_box_carousel);
app.register('jwplayer_multiple_shelfwidget_display', jwplayer_multiple_shelfwidget_display);
app.register('related_topic_redirect', related_topic_redirect);
app.register('videoboxx_video_quiz', videoboxx_video_quiz);




// Register analytics page event listener-binders.
//analytics(app);

// app.init(document.body.getAttribute('data-features'), function (params, services) {
app.init($('body').data('features'), function (params, services) {
  // Executed on all pages after modules have executed.
  if (window.ADGEAR) {
      // Bootstrap ADGEAR stuff.
      ADGEAR.tags.script.init();
      ADGEAR.lang.namespace("ADGEAR.site_callbacks");
      ADGEAR.site_callbacks.variables = function () {
          return {};
      };
  }

  /*

  // Example notifications:
  services.notify([
    { type: 'success', notification: 'false alarm. :-)' },
    { type: 'success', notification: 'Nice example of ordering by priority though... not bad.' },
    { type: 'info', notification: 'oh, never mind...' },
    { type: 'warning', notification: 'aaagh' },
    { type: 'danger', notification: 'Watch out, behind you!' }
  ]);
  setTimeout(function () {
    services.notify({ type: 'danger', notification: 'Opps something went wrong :-(' });
  }, 10000);

  // Example modal:
  services.prompt({
    header: "The modal window's title",
    body: "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur ssint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
    footer: '<button type="button" class="btn btn-default" data-dismiss="modal">Close</button> <button type="button" class="btn btn-primary">Save changes</button>'
  });

  */

});

