var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function(params) {
  var videos = params.videos || [],
      players = {},
      triggers = $('.js-user-popular-video'),
      current = 0,
      familyIntroVideo = params.family_intro_video || null;

  // setup intro video click if it is set
  if (familyIntroVideo != null){
    $('#js-user-family-intro-video').click(onPlayFamilyVideoClick);
  }

// Play specific video from video image click
  triggers.each(function (i, el) {
    $(el).click(onVideoTriggerClick);
  });

  function uhp_startPlayer() {
    uhp_ensurePlayer(current).play();
  }

  function onVideoTriggerClick(e) {
    current = $(e.currentTarget).attr('data-target');
    uhp_startPlayer();
  }

  function onPlayFamilyVideoClick(e) {
    // set title
    var descriptionField = $('#user-popular-video-player-description');
    descriptionField.text(familyIntroVideo.title);
    initPlayer(familyIntroVideo).play();
  }

  function uhp_ensurePlayer(id) {
    if (!players[id]) {
      // TODO: Currently disabling preroll ads in modal/carousel as they
      //       don't play nice.
      if (videos[id].advertising_tag) {
        delete videos[id].advertising_tag;
      }
    }
    players[id] = initPlayer(videos[id]);

    // set title
    var descriptionField = $('#user-popular-video-player-description');
    descriptionField.text(videos[id].title);

    return players[id];
  }

// initialize the first video in the player
  uhp_ensurePlayer(0);
};