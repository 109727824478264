var Initializer = function (params, services) {
    this._services = services || {};
    this._params = params || {};
    this._modules = {};
};

Initializer.prototype.register = function (name, featureInit) {
    this._modules[name] = featureInit;
};

Initializer.prototype.init = function (features, readyFn) {
    var i, len, name, names;

    if (features) {
        names = features.split(' ');
        for (i = 0, len = names.length; i < len; i++) {
            name = names[i];
            if (this._modules[name] && typeof this._modules[name] === 'function') {
                this._modules[name](this._params[name] || {}, this._services);
            }
        }
    }

    readyFn(this._params, this._services);
};

Initializer.prototype.addParam = function (feature, name, value) {
    this._params[feature] = this._params[feature] || {};
    this._params[feature][name] = value;
};

module.exports = function (params, services) {
    return new Initializer(params, services);
};
