module.exports = function (params) {
    if (!ga) return;

    $('.js-readmore-text').on('click', function (e) {
        ga('send', 'event', 'show', 'practitioner', 'bio');

    });

    // TODO: Listen for clicks on linkedin link when it is made to exist.
    // $('.linkedin-link').on('click', function (e) {
    //     ga('send', 'event', 'show', 'practitioner', 'linkedin');
    // });
};
