var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    typeahead = require('typeahead.js');

var AutoComplete = function(callBackUrl, selectorId, searchType) {
  this.callBackUrl = callBackUrl;
  this.selector = $("#" + selectorId);
  this.destSelector = $("#" + selectorId + "_id");
  this.searchType = searchType;
};

// Attach autocomplete to the selector.
AutoComplete.prototype.attach = function() {
  // Our typahead object.
  if (this.selector.length) {
    var destSelector = this.destSelector,
        url = this.callBackUrl + '?type=' + this.searchType + '&query=',
        type = this.searchType,
        ta = $(this.selector).typeahead({hint: false, highlight: true, minLength: 1}, {
          name: 'auto-complete-' + type,
          displayKey: 'label',
          source: function(q, cb) {
            // get type of search
            if ($("#siteSearchType").length) {
              searchType = $("#siteSearchType").val();
              typeParamStart = url.indexOf('?type=');
              typeCurrentString = url.substr(typeParamStart, 7);
              url = url.replace(typeCurrentString, '?type=' + searchType);
            }
            $.ajax({
              url: url + q,
              success: function(result) {
                // Clear our destination selector if it exists.
                if (destSelector.length) {
                  destSelector.val("");
                }
                cb(result);
              }
            });
          },
          templates: {
            suggestion : function(obj) { return obj.htmlLabel; }
          },
        });

    ta.on('typeahead:selected', function(event, data) {
      // In a form context we need a destination selector
      // to store the Id of the underlying object associated
      // with the suggestions string. This is provided by
      // the autocomplete form widget.
      if (data.id && destSelector.length) {
        destSelector.val(data.id).trigger('change', [{data: [data.id, data.label]}]);
      }
      // In the site search context we have a url associated
      // with the sugeestion also there should be no associated
      // destination Selector.
      else if (data.url && !destSelector.length) {
        location.href = data.url;
      }
    });
  }

};

module.exports = AutoComplete;
