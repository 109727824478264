var initNotifier = require('./notifier'),
    initModal = require('./modal');

function flatten_messages(nested) {
    var i, type, property, notification,
        notifications = [];

    for (type in nested) {
        for (i in nested[type]) {
            notification = { type: type };
            if (typeof nested[type][i] === 'string') {
                notification.notification = nested[type][i];
            }
            else {
                for (property in nested[type][i]) {
                    notification[property] = nested[type][i][property];
                }
            }
            notifications.push(notification);
        }
    }

    return notifications;
}

function assemble(messages) {
    var notifications,
        modals = messages.modal || [];

    if (messages.modal) {
        delete messages.modal;
    }

    notifications = flatten_messages(messages);

    return { modals: modals, notifications: notifications };
}

module.exports = function (params) {
    var messages = assemble(params.messages || {}),
        notifier = initNotifier(params.notificationsId),
        modals = initModal(params.modalsId, messages.modals),
        iconMap = params.icons || {};

    notify(messages.notifications);

    return {
        prompt: modals.push.bind(modals),
        notify: notify,
        messages: function (raw) {
            var messages = assemble(raw);
            notify(messages.notifications);
            modals.push(messages.modals);
        }
    };

    function notify(raw) {
        // Order of notification types: warning, info, success, everything else.
        var order = { 'danger': 20, 'warning': 15, 'info': 10, 'success': 5 },
            notifications = (!$.isArray(raw)) ? [raw] : raw;

        notifications.sort(function (a, b) {
            return (order[b.type] || 0) - (order[a.type] || 0);
        });

        notifications = notifications.map(applyIcon);

        notifier.sendMultiple(notifications);
    }

    function applyIcon(notification) {
        if (iconMap[notification.type]) {
            notification.icon = iconMap[notification.type];
        }
        return notification;
    }
};
