/**
* Toggle an element by clicking another element.
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  var revealerTrigger = $('.js-hcf-revealer-trigger'),
      revealerTarget = $('.js-hcf-revealer-target'),
      revealerTargetSel = '.js-hcf-revealer-target';
  revealerTarget.hide();
  revealerTrigger.on('click', function() {
    $(this).siblings(revealerTargetSel).toggle();
    if ($(this).children('.fa').length > 0) {
      $(this).children('.fa').toggleClass('fa-caret-down').toggleClass('fa-caret-up');
    }
  });
};