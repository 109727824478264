/**
* Show the states or the provinces depending on which country is selected.
* Also change "Postal Code" to "Zip Code" and "State" to "Province".
*
* Parameters:
*   location_container  - The css selector for a container of locations. This is
*                         used to keep the scope of the on() call relatively
*                         small.
*
*                         Inside of the location container, the script
*                         expects be able to find:
*                           .location-instance - a wrapper for each location
*                             .provinces - the provinces select
*                             .postcode  - the postcode field.
*
*   states              - The list of US states
*   provinces           - The list of Canadian provinces
*/

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

function updateStateProvinceList(countrySelect, params) {
    var location = countrySelect.closest('.location-instance'),
        provinceSelect = $('.provinces', location),
        states = params.states,
        provinces = params.provinces,
        province_options = '<option value="">--- Choose ---</option>',
        state_options = '<option value="">--- Choose ---</option>',
        selectedProvince = provinceSelect.val();

    $.each(provinces, function(key, value) {
        var selected = key === selectedProvince ? 'selected' : '';
        province_options += '<option value="' + key + '" ' + selected + ' >' + value + '</option>';
    });
    $.each(states, function(key, value) {
        var selected = key === selectedProvince ? 'selected' : '';
        state_options += '<option value="' + key + '" ' + selected + ' >' + value + '</option>';
    });

    // CANADA
    if ($(countrySelect).val() == 'CA') {
        provinceSelect.html(province_options);
        $("label[for='" + provinceSelect.attr('id') + "']").html('Province');
        $("label[for='" + $('.postcode', location).attr('id') + "']").html('Postal Code');
    }
    // USA
    else if ($(countrySelect).val() == 'US') {
        provinceSelect.html(state_options);
        $("label[for='" + provinceSelect.attr('id') + "']").html('State');
        $("label[for='" + $('.postcode', location).attr('id') + "']").html('Zip Code');
    }
  }

module.exports = function (params) {

  var location_container = $(params.location_container);

  $(params.location_container).on( 'change', '.location-instance .country', params, function( event ) {
    updateStateProvinceList($(this), event.data);
  });

  $('.location-instance .country', $(params.location_container)).trigger('change');

};
