var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

function show(ele) {
  return ele.toggleClass('hidden', false);
}

function hide(ele) {
  return ele.toggleClass('hidden', true);
}

function isVisible(ele) {
  return (! ele.hasClass('hidden'));
}

function disableForm(ele) {
  ele.find(':input').prop('disabled', true);
}

function enableForm(ele) {
  ele.find(':input').prop('disabled', false);
}


module.exports = function(params) {
  var searchBusinessesUrl = params.searchBusinessesUrl;
  var searchBusinessButton = $('#hcf-business-search-registration');
  var businessNameField = $('#user_created_business_name');
  var businessSearchListing = $('#claim-profile-list');
  var premierBusinessesRight = $('#premier-businesses-right');
  var premierBusinessesLeft = $('#premier-businesses-left');
  var businessReasons = $('#business-reasons')

  searchBusinessButton.unbind('click').click(function () {
    var searchTerm = businessNameField.val();
    $.ajax({
      type: "POST", url: searchBusinessesUrl, data: {query: searchTerm},
      success: function (response) {
        var businesses = JSON.parse(response).business_listing_html;
        hide($(premierBusinessesRight))
        hide($(businessReasons));
        show($(premierBusinessesLeft));

        hide(businessSearchListing);
        $(businessSearchListing).empty();
        $(businessSearchListing).append(businesses);

        $('.hcf-claiming-business').click(function () {
          if ($(this).data('business-claimed')) {
            $('#hcf-business-claimed-modal').modal('show');
          }
          else{
            location.href = $(this).data('business-edit');
          }
        });

        show($(businessSearchListing));
      },
      error: function(xhr, status, error){

      },
      dataType: 'json'
    });
  });
};