var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
	var	likeSubmitUrl = params.like_click_url,
    practitionerLikeSlug = params.slug,
    likeTrigger = $('.practitioner-like');
    
  likeTrigger.on('click', function() {
    if (typeof ga !== 'undefined'){
      ga('send', 'event', 'practitioner-like', practitionerLikeSlug);
    }
    
    //increment like count in database
    $.get(likeSubmitUrl || null);
    
    // reload window to update count on screen and get another page view in analytics
    window.location.reload(true);
  });
};  
