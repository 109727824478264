var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    util = require('util'),
    typeahead = require('typeahead.js'),
    GoogleMapsLoader = require('google-maps');

module.exports = function (params, services) {
    var input = $(util.format('#%s_name', params.prefix)),
        coords = $(util.format('#%s_coords', params.prefix));
	
	if (!params.location.name || 0 === params.location.name.length)
		params.location.name = "Unknown location";
		
	input.text(params.location.name);
	
	$('#location_submit').on('click', function (e) {
		// set session to selected location
		var locationSelectedId = $("#location-search_id");
		$('#hcf-change-location-modal').modal('hide');
		$.ajax({ type: "POST", url: params.setSelectedLocationUrl, data: {locationId: locationSelectedId.val()}, success: setLocationSuccess, async: false});
    });
};

	
function setLocationSuccess(){
	location.reload(true);
}
