/**
 * Character counter
 */

module.exports = function (params) {
	var max_count = 10000,
	selector = 'textarea';

	$(selector).each(function() {
	  var count = $(this).val().length;

	  $(this)
	    .attr('maxlength', max_count)
	    .wrap('<div class="js-character-counter"></div>')
	    .before('<div class="counter">Maximum <span class="count">' + (max_count - count) + '</span> Characters</div>')
	    .keyup(function() {
			  $(this)
			    .prev()
			      .children('.count').text(max_count - $(this).val().length);
			});
	});
};