var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    Ractive = require('ractive');

function Modal(container, messages) {
    this._active = false;
    this._current = 0;
    this._messages = messages || [];
    this._modals = new Ractive({
        el: '#' + container,
        template: '#' + container + '-template',
        data: { modals: this._messages }
    });

    $(document).on('show.bs.modal', '.modal', this._onOpen.bind(this));
    $(document).on('hidden.bs.modal', '.modal', this._onClosed.bind(this));
}

Modal.prototype.push = function (content) {
    var messages = this._messages;
    if ($.isArray(content)) {
        content.forEach(function (message) {
            messages.push(message);
        });
    } else {
        messages.push(content);
    }
    this._modals.update('modals');

    if (!this._active) {
        this.trigger();
    }
};

Modal.prototype.trigger = function () {
    if (this._active) return;
    $('#messaging-modal-' + this._current).modal();
};

Modal.prototype._onOpen = function () {
    this._active = true;
};

Modal.prototype._onClosed = function () {
    var i = this._current = this._current + 1,
        next = $('#messaging-modal-' + i);

    if (!next.length) {
        this._active = false;
        return this._active;
    }

    next.modal();
};

module.exports = function (container, modals) {
    return new Modal(container, modals);
};
