var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    jwplayer = (typeof window !== "undefined" ? window['jwplayer'] : typeof global !== "undefined" ? global['jwplayer'] : null);

module.exports = function initVideoPlayer(params) {

  var onCompleteCounter = 0;
  var listOfVideos = params.videos;
  var singleVideo = params.single_video;
  var videoArray = [];

  if (params.shelfwidget_display) {
    singleVideo = listOfVideos[0];
  }

  var player = jwplayer(singleVideo.id || 'hcf-jwplayer');

  if (!player) return;

  var options = {};
  var playing = false;

  if (!params.shelfwidget_display) {
    options = {
      title: singleVideo.title,
      autostart: singleVideo.autostart || false,
      aspectratio: singleVideo.aspectratio || '16:9',
      width: '100%',
      image: singleVideo.image,
      primary: 'html5',
      ga: {"label": "title",
        "sendEnhancedEvents": false},
      sources: [],
      mute: true
    };

    if (singleVideo.file) {
      options.sources.push({
        file: singleVideo.file,
        label:'1080p HD',
        'default' : 'true'
      });
    }

    if (singleVideo.sdfile) {
      options.sources.push({
        file: singleVideo.sdfile,
        label: '360p SD'
      });
      if (!singleVideo.file) {
        options.sources[0]['default'] = true;
      }
    }

    if (singleVideo.advertising_tag) {
      options.advertising = singleVideo.advertising_tag;
    }

    if (typeof singleVideo.stretching !== 'undefined') {
      options.stretching = singleVideo.stretching;
    }
  }
  else {
    for (var i = 0; i < listOfVideos.length; i++){
      videoArray.push({
        'title': listOfVideos[i].title,
        'description': listOfVideos[i].description,
        'image': listOfVideos[i].image,
        'advertising': listOfVideos[i].advertising_tag ? listOfVideos[i].advertising_tag : '',
        'sources': [{
          'file': 'https://www.healthchoicesfirst.com/' + listOfVideos[i].sdfile,
          'label': '360p SD'
        },
          {'file': 'https://www.healthchoicesfirst.com/' + listOfVideos[i].file,
            'label': '1080p HD',
            'default' : 'true'
          }
        ]
      });
    }

    options = {
      autostart: params.videos[0].autostart || false,
      aspectratio: params.videos[0].aspectratio || '16:9',
      width: '100%',
      image: params.videos[0].image,
      primary: 'html5',
      ga: {"label": "title",
        "sendEnhancedEvents": false},
      playlist: videoArray,
      related: {
        displayMode: "shelfWidget",
        autoplaytimer: 10,
        oncomplete: "autoplay"
      },
      mute: true
    };

    if (params.videos[0].advertising_tag) {
      options.advertising = params.videos[0].advertising_tag;
    }
  }

  var playerInstance = player.setup(options);

  if (typeof singleVideo.controls !== 'undefined') {
    player.setControls(singleVideo.controls);
  }

  playerInstance.on('play',function (e) {
    var url = singleVideo.statsUrl || null;
    var recordStats = singleVideo.recordStats || null;
    //update piwik stats for video
    // if (recordStats != null){
    //   if (!(typeof _paq === 'undefined' || _paq === null)) {
    //     var dim3Value = singleVideo.statsPractitionerTypeId != 'undefined' ? singleVideo.statsPractitionerTypeId : null;
    //     _paq.push(['trackEvent', 'Video', 'Play', singleVideo.video_id, 0, {
    //       dimension1: singleVideo.statsPage,
    //       dimension2: singleVideo.statsPageId,
    //       dimension3: dim3Value
    //     }]);
    //   }
    // }

    if (playing || !url) return;

    playing = true;

    // Request to server for stats.
    $.get(singleVideo.statsUrl);
  });

  playerInstance.on('complete', function (e) {

    if (typeof singleVideo.defaultCompleteUrl !== 'undefined') {
      if (onCompleteCounter < 1) {
        onCompleteCounter++;
        window.location.href = singleVideo.defaultCompleteUrl;
      }
    }
  });

  return playerInstance;
};
