var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

/**
 * Play videos when selected/clicked from a list of video images
 */
module.exports = function (params) {
  'use strict';

  var sampleVideoSections = params.sections,
      sampleVideoSectionVideos = params.sectionVideos,
      triggers = $('.js-sample-videos');

  // Play specific video from video image click
  triggers.each(function (i, el) {
    $(el).click(onVideoTriggerClick);
  });

  function onVideoTriggerClick(e) {
    var currentSection = $(e.currentTarget).attr('data-video-section');
    // find index in video sections to use to get videos
    var currentSectionIndex = sampleVideoSections.indexOf(currentSection);
    var videoIndex = $(e.currentTarget).attr('data-target');
    var videoToPlay = sampleVideoSectionVideos[currentSectionIndex][videoIndex];
    ensurePlayer(videoToPlay).play();
  }

  function ensurePlayer(video) {
    var currentPlayer = initPlayer(video);

    // set title and description
    //var titleField = $('#pharma-sample-video-title');
    //titleField.text(videos[id].title);

    return currentPlayer;
  }

  // initialize the first video in the player
  for (var videoSectionIndex = 0; videoSectionIndex < sampleVideoSections.length; videoSectionIndex++){
    ensurePlayer(sampleVideoSectionVideos[videoSectionIndex][0]);
  }


};

