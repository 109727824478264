var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    jwplayer = (typeof window !== "undefined" ? window['jwplayer'] : typeof global !== "undefined" ? global['jwplayer'] : null);

module.exports = function initVideoPlayerPlaylist(params) {
  var player = jwplayer(params.videos[0].id || 'hcf-jwplayer');
  if (!player) return;

  var listOfVideos = params.videos;
  var videoArray = [];
  var videoTitleElement = params.videoTitleElement;
  var videoTitleContainerElement = $('#hcf-video-iframe-title-container');
  var videoViewCountElement = params.videoViewCountElement;
  var videoTranscriptElement = params.videoTranscriptElement;
  var videoDescriptionElement = params.videoDescriptionElement;
  var relatedVideoViewTrigger = $('.relatedViewTrigger');
  var relatedHideTrigger = $('.relatedHideTrigger');
  var initialStartupTimer;
  var initialStartup = 1;
  var muteIconContainer = $('#hcf-video-mute-button');
  var hcfVideoContainer = $('.hcf-video-player');
  var hcfMuteIconText = $('#hcf-video-mute-text');

  for (var i = 0; i < params.videos.length; i++){
    videoArray.push({
      'title': params.videos[i].title,
      'description': params.videos[i].description,
      'image': params.videos[i].image,
      'sources': [{
        'file': 'https://www.healthchoicesfirst.com/' + params.videos[i].sdfile,
        'label': '360p SD'
      },
        {'file': 'https://www.healthchoicesfirst.com/' + params.videos[i].file,
          'label': '1080p HD',
          'default' : 'true'
        }
      ]
    });
  }

  var autoStartSetting;
  if (typeof params.videos[0].autostart === 'undefined' || params.videos[0].autostart === null) {
    autoStartSetting = true;
  }
  else{
    autoStartSetting = params.videos[0].autostart;
  }

  var playing = false,
      options = {
        autostart: autoStartSetting,
        aspectratio: '16:9',
        width: '100%',
        image: 'https://www.healthchoicesfirst.com/' + params.videos[0].image,
        primary: 'html5',
        ga: {},
        playlist: videoArray,
        // related: {
        //   displayMode: "shelfWidget",
        //   autoplaytimer: 10,
        //   oncomplete: "autoplay"
        // },
        related: videoArray,
        nextupoffset: 10,
        mute: true
      };

  if (params.videos[0].advertising_tag) {
    options.advertising = params.videos[0].advertising_tag;
  }

  var playerInstance = player.setup(options);
  document.getElementsByClassName('jw-text-alt').innerHTML='';

  // var relatedPluginDisplay = playerInstance.getPlugin('related');
  // relatedPluginDisplay.open();

  relatedVideoViewTrigger.click(function (event) {
    relatedPlugin = playerInstance.getPlugin('related');
    videoTitleContainerElement.removeClass('hcf-responsive-title-container-display');
    videoTitleContainerElement.addClass('hcf-responsive-title-container-hide');
    relatedPlugin.open();
  });

  relatedHideTrigger.click(function (event) {
    relatedPlugin = playerInstance.getPlugin('related');
    relatedPlugin.close();
    videoTitleContainerElement.removeClass('hcf-responsive-title-container-hide');
    videoTitleContainerElement.addClass('hcf-responsive-title-container-display');
  });

  playerInstance.on('play',function (e) {
    // clear initial display of related videos if user has selected a video
    clearTimeout(initialStartupTimer);
    var playListIndex = playerInstance.getPlaylistIndex();
    var url = listOfVideos[playListIndex].statsUrl || null;
    var recordStats = listOfVideos[playListIndex].recordStats || null;
    document.getElementsByClassName('jw-text-alt').innerHTML='';
    if( $('#' + videoTitleElement).length ){
      $('#' + videoTitleElement).text(listOfVideos[playListIndex].title);
    }
    if( $('#' + videoViewCountElement).length ){
      $('#' + videoViewCountElement).text(listOfVideos[playListIndex].views + ' Views');
    }
    if( $('#' + videoTranscriptElement).length ){
      $('#' + videoTranscriptElement).html(listOfVideos[playListIndex].transcript);
    }
    if( $('#' + videoDescriptionElement).length ){
      $('#' + videoDescriptionElement).html(listOfVideos[playListIndex].description);
    }

    // remove next-up card on display after it has been displayed
    // for ~10 seconds (pop up is delayed 10 seconds from start of video before being displayed)
    // var hideNextUpCard = setTimeout(function () {
    //   var jwPlayerNextUpCardContainer = $('.jw-nextup-container');
    //   jwPlayerNextUpCardContainer.removeClass('jw-nextup-container-visible');
    //   clearTimeout(hideNextUpCard);
    // }, 18000);

    // only run the first time through
    // should be able to have called this from jw onReady but the call to display
    // the related UI failed in the onReady event but works here
    // if (initialStartup === 1) {
    //   relatedPlugin = playerInstance.getPlugin('related');
    //   videoTitleContainerElement.removeClass('hcf-responsive-title-container-display');
    //   videoTitleContainerElement.addClass('hcf-responsive-title-container-hide');
    //   relatedPlugin.open();
    //   initialStartup = 0;
    //
    //   // countdown 5 seconds
    //   initialStartupTimer = setTimeout(function(){
    //     relatedPlugin = playerInstance.getPlugin('related');
    //     relatedPlugin.close();
    //     videoTitleContainerElement.removeClass('hcf-responsive-title-container-hide');
    //     videoTitleContainerElement.addClass('hcf-responsive-title-container-display');
    //   }, 5000);
    // }



    //update piwik stats for video
    // if (listOfVideos[playListIndex].recordStats != null){
    //   if (!(typeof _paq === 'undefined' || _paq === null)) {
    //     var dim3Value = listOfVideos[playListIndex].statsPractitionerTypeId != 'undefined' ? listOfVideos[playListIndex].statsPractitionerTypeId : null;
    //     _paq.push(['trackEvent', 'Video', 'Play', listOfVideos[playListIndex].video_id, 0, {
    //       dimension1: listOfVideos[playListIndex].statsPage,
    //       dimension2: listOfVideos[playListIndex].statsPageId,
    //       dimension3: dim3Value
    //     }]);
    //   }
    // }

    // relatedPlugin = playerInstance.getPlugin('related');
    // videoTitleContainerElement.removeClass('hcf-responsive-title-container-display');
    // videoTitleContainerElement.addClass('hcf-responsive-title-container-hide');
    // relatedPlugin.open();

    playerInstance.on('mute',function(){
      if (playerInstance.getMute()) {
        muteIconContainer.show();
      }
      else
        muteIconContainer.hide();
      hcfMuteIconText.hide();
    });

    muteIconContainer.click(function (event) {
      playerInstance.setMute(false);
      playerInstance.setVolume(75);
      muteIconContainer.hide();
      if (playerInstance.getState() !== "playing") {
        playerInstance.play();
      }
    });

    hcfVideoContainer.mouseover(function() {
      if (playerInstance.getMute()) {
        hcfMuteIconText.show();
      }
    });

    hcfVideoContainer.mouseout(function() {
      hcfMuteIconText.hide();
    });

    if (playing || !url) return;

    playing = true;

    // Request to server for stats.
    $.get(listOfVideos[playListIndex].statsUrl);
  });

  return playerInstance;
};
