var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    jwplayer = (typeof window !== "undefined" ? window['jwplayer'] : typeof global !== "undefined" ? global['jwplayer'] : null);

module.exports = function initVideoPlayerPlaylist(params) {
  var player = jwplayer(params.videos[0].id || 'hcf-jwplayer');
  if (!player) return;

  var listOfVideos = params.videos;
  var videoArray = [];
  var videoTitleElement = params.videoTitleElement;
  var videoViewCountElement = params.videoViewCountElement;
  var videoTranscriptElement = params.videoTranscriptElement;
  var videoDescriptionElement = params.videoDescriptionElement;
  var muteIconContainer = $('#hcf-video-mute-button');
  var hcfVideoContainer = $('.hcf-video-player');
  var hcfMuteIconText = $('#hcf-video-mute-text');

  for (var i = 0; i < params.videos.length; i++){
    videoArray.push({
      'title': params.videos[i].title,
      'description': params.videos[i].description,
      'image': params.videos[i].image,
      'sources': [{
        'file': params.videos[i].sdfile,
        'label': '360p SD'
        },
        {'file': params.videos[i].file,
          'label': '1080p HD',
          'default' : 'true'
        }
      ]
    });
  }

  var playing = false,
      options = {
        title: params.videos[0].title,
        autostart: true,
        aspectratio: '16:9',
        width: '100%',
        image: params.videos[0].image,
        primary: 'html5',
        ga: {"label": "title",
          "sendEnhancedEvents": false},
        playlist: videoArray,
        mute: true
      };

  if (params.videos[0].advertising_tag) {
    options.advertising = params.videos[0].advertising_tag;
  }

  var playerInstance = player.setup(options);
//  document.getElementsByClassName('jw-text-alt').innerHTML='';

  playerInstance.on('play',function (e) {
    var playListIndex = playerInstance.getPlaylistIndex();
    var url = listOfVideos[playListIndex].statsUrl || null;
    var recordStats = listOfVideos[playListIndex].recordStats || null;
//    document.getElementsByClassName('jw-text-alt').innerHTML='';
    if( $('#' + videoTitleElement).length ){
      $('#' + videoTitleElement).text(listOfVideos[playListIndex].title);
    }
    if( $('#' + videoViewCountElement).length ){
      $('#' + videoViewCountElement).text(listOfVideos[playListIndex].views + ' Views');
    }
    if( $('#' + videoTranscriptElement).length ){
      $('#' + videoTranscriptElement).html(listOfVideos[playListIndex].transcript);
    }
    if( $('#' + videoDescriptionElement).length ){
      $('#' + videoDescriptionElement).html(listOfVideos[playListIndex].description);
    }

    //update piwik stats for video
    // if (listOfVideos[playListIndex].recordStats != null){
    //   if (!(typeof _paq === 'undefined' || _paq === null)) {
    //     var dim3Value = listOfVideos[playListIndex].statsPractitionerTypeId != 'undefined' ? listOfVideos[playListIndex].statsPractitionerTypeId : null;
    //     _paq.push(['trackEvent', 'Video', 'Play', listOfVideos[playListIndex].video_id, 0, {
    //       dimension1: listOfVideos[playListIndex].statsPage,
    //       dimension2: listOfVideos[playListIndex].statsPageId,
    //       dimension3: dim3Value
    //     }]);
    //   }
    // }

    if (playing || !url) return;

    playing = true;

    // Request to server for stats.
    $.get(listOfVideos[playListIndex].statsUrl);
  });

  playerInstance.on('mute',function(){
    if (playerInstance.getMute()) {
      muteIconContainer.show();
    }
    else
      muteIconContainer.hide();
    hcfMuteIconText.hide();
  });

  muteIconContainer.click(function (event) {
    playerInstance.setMute(false);
    playerInstance.setVolume(75);
    muteIconContainer.hide();
    if (playerInstance.getState() !== "playing") {
      playerInstance.play();
    }
  });

  hcfVideoContainer.mouseover(function() {
    if (playerInstance.getMute()) {
      hcfMuteIconText.show();
    }
  });

  hcfVideoContainer.mouseout(function() {
    hcfMuteIconText.hide();
  });

  return playerInstance;
};
