/**
* Print functionality on modal window
*/

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {

  var printModal = function() {
  	var target = $(this).attr('data-target'),
  	    hiddenPrint = $('body').children(':not(script):not(.hidden-print)').addClass('hidden-print');

  	$('body').append('<div id="print-modal" class="visible-print-block">' + $(target).html() + '</div>');
  	window.print();
  	hiddenPrint.removeClass('hidden-print');
  	$('#print-modal').remove();
  };

  $( ".modal" ).on( "click", ".btn-print", printModal);
};
