var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function (params) {
  var dataTrigger = $('.practitioner-data'),
    practitionerSlug = params.slug,
    practitionerId = params.id,
    incrementStatsUrl = params.increment_stats_url;

  dataTrigger.on('click', function() {
    var currentTarget = $(this).data('stat-type');  // reusing data type for video library display
    var currentUrlNoParameters = window.location.href;
    var currentHost = window.location.host;
    var currentUrl = window.location.href;
    var redirectPage = false;

    if (currentTarget == 'bioClicks') {
      $('#collapsableBio').slideToggle();
    }
    else if (currentTarget == 'onlineBookingClick') {
      practitionerId = $(this).data('pract-id');
    }
    else if (currentTarget == 'libraryClicks') {
      redirectPage = true;
      urlWithParameter = '//' + window.location.host + '/practitioner/' + practitionerSlug + '?hcf_library=1';
    }
    
    //increment practitioner stat
    $.ajax({
      type: "POST", url: incrementStatsUrl, data: {id: practitionerId, statType: currentTarget},
      success: function (response) { // for debug
        var i = 1 + i;
      },
      error: function (xhr, status, error) {
        var i = 1 + i;
      },
      dataType: 'json', async: true
    });

    if (redirectPage) {
      window.location = urlWithParameter;
    }
    
  });
};