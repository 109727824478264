var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
	var	referSubmitUrl = params.refer_action_url,
    businessShareSlug = params.slug,
    referSubject = params.refer_subject,
    referBody = params.refer_body,
    referTrigger = $('.business-refer');
    
  referTrigger.on('click', function(e) {
    if (typeof ga !== 'undefined'){
      ga('send', 'event', 'business-share', businessShareSlug);
    }
    
     e.preventDefault();
    
    //increment refer count in database
    $.ajax({type: "GET", url: referSubmitUrl, success: function(response){showEmail();}, error: function(xhr, status, error) {myerror(xhr, status, error);}});

    return false;
  });
  
  function myerror(xhr, status, error){
  }
  
  // reload window to update count on screen and get another page view in analytics
  function showEmail(){
    window.location.href = "mailto:?subject=" + referSubject +  "&body=" + referBody;
  }
};  
