var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    emit = require('./eventemitter'),
    AutoComplete = require('./autocomplete'),
    initiatedFields = [];

function initAutoCompleteFormFields(callBackUrl) {
  // This class is on every autocomplete custom form field
  var acSelector = $(".autocomplete-input-field");
  $.each($("form").find(acSelector), function(key, value) {
    var id = $(value).attr("id");
    var type = $(value).attr("ac_type");
    // Check if we've already attached autocomplete to this field.
    if (id && $.inArray(id, initiatedFields) == -1) {
      initiatedFields.push(id);
      // Create our new autocomplete object
      var ac = new AutoComplete(callBackUrl, id, type);
      ac.attach();
    }
    $(this)
      .parents('.form-control:first')
        .addClass('js-autocomplete');
  });
}

module.exports = function(callBackUrl) {
  initAutoCompleteFormFields(callBackUrl);
  emit.on('postAddAnother', function() { initAutoCompleteFormFields(callBackUrl); });
};
