/*
 * Redirect users to the proper Practitioner Type page
 */
module.exports = function (params) {
  function redirect(evt) {
    evt.preventDefault();
    var pTypeSelect = document.getElementById('practype-redirect-select');

    if (pTypeSelect.length) {
      var url = pTypeSelect.options[pTypeSelect.selectedIndex].value;
      window.location.href = url;
    }
  }

  var pTypeSubmit = document.getElementById('practype-submit');

  if (pTypeSubmit) {
    pTypeSubmit.addEventListener('click', redirect, false);
  }
};