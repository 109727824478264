/**
* Trim text and add 'read more' link beneath.
* Using https://github.com/jedfoster/Readmore.js
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  if ($('.js-readmore-text').length > 0) {
    var readMoreText = $('.js-readmore-text'),
        readMoreExpand = '<a href="#"><span>read more</span><i class="fa fa-caret-down"></i></a>',
        readMoreCollapse = '<a href="#"><span>read less</span><i class="fa fa-caret-up"></i></a>';

    // Expand text.
    readMoreText.readmore({
      speed: params.speed || 0,
      maxHeight: params.maxHeight || 45,
      moreLink: readMoreExpand,
      lessLink: readMoreCollapse
    });
  }
};
