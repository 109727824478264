var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer'),
    typeahead = require('typeahead.js');

module.exports = function(params) {
  this.callBackUrl = params.callBackUrl;
  this.selector = $("#" + params.selectorId);
  this.destSelector = $("#" + params.selectorId + "_id");
  this.searchType = params.searchType;
  videoSearchUrl = params.videoSearchUrl;
  var selectedVideos = [];
  var searchVideoArray = [];
  var currentVideo = 0;
  var players = [];

  $.fn.getVideoSearchResults = function (searchString){
    $.ajax({
      url: videoSearchUrl + searchString,
      success: function(response) {
        var resultsList = $('#user-video-search-results');
        var results = $.parseJSON(response);
        resultsList.html(results.html);
        searchVideoArray = results.videos;
        // set check event for selecting videos

        $.each($('.video-selection-checkbox'), function(key, value) {
          $(value).change(function(){
            if (this.checked){
              // add video data to array
              // check if they have a max of 10 videos selected
              var maxVideoCount = selectedVideos.length + $("#library-videos-list li").length;

              if (maxVideoCount > 9){
                $(this).attr("checked", false);
                $('#user-educational-max-videos').modal('show');
              }
              else {
                var currentVideoData = searchVideoArray[$(this).data('index')];
                var videoId = currentVideoData['video_id'];
                var videoTitle = currentVideoData['title'];
                var videoDescription = currentVideoData['description'];
                var videoImage = '/image_styles/video_large_thumb' + currentVideoData['image'];
                selectedVideos.push({
                  id: videoId,
                  image: videoImage,
                  title: videoTitle,
                  description: videoDescription
                });
              }
            }
            else {
              // remove video data from array
              var videoData = searchVideoArray[$(this).data('index')];
              var removeVideoId = videoData['video_id'];
              selectedVideos = $.grep(selectedVideos, function(el, idx) {return el.id == removeVideoId}, true);
            }
          })
        });

        var videoPlayClick = $(".hcf-user-video-search-action"),
            videoModal = $('#hcf-user-stats-video-modal');

        videoModal.unbind();

        videoModal.on('show.bs.modal', function (event) {
          videoSearchStartPlayer();
        });

        videoModal.on('hide.bs.modal', function (event) {
          videoSearchStopPlayer();
        });

        videoPlayClick.unbind('click').click(function() {
          currentVideo = $(this).data('video-target');
          videoModal.modal('show');
        });

        var addVideosButtonClick = $(".user-educational-videos-add");
        addVideosButtonClick.unbind('click').click(function() {
          var videoList = $('#library-videos-list');
          var currentIndex = videoList.data('index');

          for (index = 0; index < selectedVideos.length; index++) {
            var newWidget = videoList.data('prototype');
            newWidget = newWidget.replace(/__name__/g, currentIndex);
            var $newFormLi = $('<li data-video-id="' + selectedVideos[index]['id'] + '" data-index="100"></li>').append(newWidget);
            $newFormLi.appendTo(videoList);
            //var autoCompleteSelector = $("#" + $newFormLi.children(":first").attr("id") + '_input');
            //autoCompleteSelector.val(selectedVideos[index]['title']);
            var videoTitleSelector = $("#" + $newFormLi.children(":first").attr("id") + '_video_input');
            videoTitleSelector.val(selectedVideos[index]['title']);
            var videoSelector = $("#" + $newFormLi.children(":first").attr("id") + '_video_input_id');
            videoSelector.val(selectedVideos[index]['id']);
            var videoSortOrder = $("#" + $newFormLi.children(":first").attr("id") + '_videoSortOrder');
            videoSortOrder.val('100');
            $('#video-title-' + currentIndex).html(selectedVideos[index]['title']);
            $('#video-description-' + currentIndex).html(selectedVideos[index]['description']);
            $('#video_image-' + currentIndex).attr('src', selectedVideos[index]['image']);
            $('#video-remove-' + currentIndex).attr('data-video-id', selectedVideos[index]['id']);
            $('#video-remove-' + currentIndex).attr('data-video-title', selectedVideos[index]['title']);
            currentIndex++;
          }

          videoList.data('index', currentIndex);

          $('#hcf-profile-video-search').modal('hide');
        });
      }
    });
  }

  if (this.selector.length) {
    var destSelector = this.destSelector,
        url = this.callBackUrl + '?type=' + this.searchType + '&query=',
        type = this.searchType,
        ta = $(this.selector).typeahead({hint: false, highlight: true, minLength: 1}, {
          name: 'auto-complete-' + type,
          displayKey: 'label',
          source: function(q, cb) {
            // get type of search
            if ($("#siteSearchType").length) {
              searchType = $("#siteSearchType").val();
              typeParamStart = url.indexOf('?type=');
              typeCurrentString = url.substr(typeParamStart, 7);
              url = url.replace(typeCurrentString, '?type=' + searchType);
            }
            $.ajax({
              url: url + q,
              success: function(response) {
                // Clear our destination selector if it exists.
                if (destSelector.length) {
                  destSelector.val("");
                }
                cb(response);
              }
            });
          },
          templates: {
            suggestion : function(obj) { return obj.htmlLabel; }
          }
        });

    ta.on('typeahead:selected', function(event, data) {
      // In a form context we need a destination selector
      // to store the Id of the underlying object associated
      // with the suggestions string. This is provided by
      // the autocomplete form widget.
      if (data.id && destSelector.length) {
        destSelector.val(data.id);
      }
      // In the site search context we have a url associated
      // with the sugeestion also there should be no associated
      // destination Selector.
      else if (data.searchString && !destSelector.length) {
        $.fn.getVideoSearchResults(data.searchString);
      }
    });

    $('#hcf-profile-video-search').on('hidden.bs.modal', function () {
      var videoSearch = $('#user-video-search');
      var currentValue = videoSearch.typeahead('val');
      videoSearch.typeahead('val', '');
      selectedVideos = [];
      searchVideoArray = [];
      players = [];
      currentVideo = 0;
      var resultsList = $('#user-video-search-results');
      resultsList.html('No results from search');

      var addVideoSearchReturnAction = $('#addVideosReturn');
      addVideoSearchReturnAction.click();
    });

    function videoSearchStartPlayer() {
      $('#hcf-video-stats-player-title').html(searchVideoArray[currentVideo].title);
      videoSearchEnsurePlayer(currentVideo).on("ready", function() {
        videoSearchEnsurePlayer(currentVideo).play();
      });
    }

    function videoSearchStopPlayer() {
      if (currentVideo !== undefined) {
        videoSearchEnsurePlayer(currentVideo).stop();
      }
    }

    function videoSearchEnsurePlayer(id) {
      if (!players[id]) {
        // TODO: Currently disabling preroll ads in modal/carousel as they
        //       don't play nice.
        if (searchVideoArray.length > 0){
          if (searchVideoArray[id].advertising_tag) {
            delete searchVideoArray[id].advertising_tag;
          }
          players[id] = initPlayer(searchVideoArray[id]);
        }
      }
      return players[id];
    }
  }
};