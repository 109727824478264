var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer');

module.exports = function(params) {
  var deleteLibraryButtons = $('.hcf-user-remove-library');
  var userDeleteLibraryUrl = params.deleteLibraryUrl;
  var practitionerEducationalLibrarySaveOrderUrl = params.practUserEdLibrarySortOrder;
  var sortModal = $("#saving-educational-library-sort-order");
  var currentSortModal;

  // setup delete library buttons
  deleteLibraryButtons.unbind('click').click(function(event) {
    var libraryTitle = $(this).data('library-title');
    var libraryId = $(this).data('library-id');
    var deleteModal = $('#user-educational-delete-modal');
    var modalDeleteButton = $('#modal-confirm-delete-button');
    $('#user-confirm-delete-header').html('Delete Educational Library');
    $('#user-confirm-delete-text').html(libraryTitle);

    modalDeleteButton.unbind('click').click(function(event){
      // remove video library
      $.ajax({ type: "POST", url: userDeleteLibraryUrl, data: {educationalVideoLibraryId: libraryId},
        success: function(response){},
        dataType: 'json', async: false});

      deleteModal.modal('hide');
      location.reload(true);
    });

    deleteModal.modal('show');

  });

  $('[data-toggle="popover"]').popover(
    { delay: 0,
      trigger: 'hover',
      container: 'body'
    }
  );

  sortModal.on('show', function (event) {
    currentSortModal = $(this);
  });

  $( "#educational-library-list" ).sortable({
    placeholder: "ui-state-highlight",
    start: function( event, ui ) {
      $('.popover').popover('hide');
    },
    stop: function( event, ui ) {
      var newSortOrder = [];
      var listItems = $("#educational-library-list li");
      listItems.each(function(idx, li) {
        $(li).attr("data-index", idx);
        newSortOrder.push({id: $(li).attr("data-id"), index: idx});
      });

      // save new sort order on server
      if (newSortOrder.length > 0) {
        // show Saving Sort Order message
        sortModal.modal('show');
        $.ajax({
          type: "POST", url: practitionerEducationalLibrarySaveOrderUrl, data: {newSortOrder: newSortOrder},
          success: function (response) {
            // remove Saving Sort Order message
            sortModal.find('#sort-saving-description').text('The new Sort Order has been saved.');
            $('#sort-saving-description').attr('class', 'hcf-user-sort-order-saved')
          },
          error: function() {
            // remove Saving Sort Order message
            $("#saving-educational-library-sort-order").modal('hide');
          },
          dataType: 'json', async: true
        });
      }
    }
  });
  $( "#educational-library-list" ).disableSelection();

};