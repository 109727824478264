var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
	var	shareSubmitUrl = params.share_action_url,
    practitionerShareSlug = params.slug,
    shareSubject = params.share_subject,
    shareBody = params.share_body,
    shareTrigger = $('.practitioner-share');
    
  shareTrigger.on('click', function() {
    if (typeof ga !== 'undefined'){
      ga('send', 'event', 'practitioner-share', practitionerShareSlug);
    }
    
    //increment share count in database
    $.get(shareSubmitUrl || null);
    
    window.location.href = "mailto:?subject=" + shareSubject +  "&body=" + shareBody;
  });
};  
