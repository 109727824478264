var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null),
    initPlayer = require('./jwplayer'),
    bootstrapCarouselSwipe = require('bootstrap-carousel-swipe'),
    currentPractitionerId = 0;

module.exports = function(params) {
  var current,
      videos = params.videos || [],
      players = {},
      statsVideoModal = $('#hcf-user-stats-video-modal'),
      featuredVideoTrigger = $('.hcf-pract-loggedin-video-trigger'),
      triggers = $('.hcf-user-stats-video-action');

  statsVideoModal.on('show.bs.modal', function (event) {
    statsStartPlayer();
  });
  statsVideoModal.on('hide.bs.modal', function (event) {
    statsStopPlayer();
  });

  triggers.on('click', function() {
    current = $(this).attr('video-target');
    statsVideoModal.modal('show');
  });

  featuredVideoTrigger.on('click', function() {
    current = $(this).data('video-index');
    statsVideoModal.modal('show');
  });

  function statsStartPlayer() {
    $('#hcf-video-stats-player-title').html(videos[current].title);
    initPlayer(videos[current]);
    ensurePlayer(current).on("ready", function() {
      ensurePlayer(current).play();
    });
  }

  function statsStopPlayer() {
    if (current !== undefined) {
      ensurePlayer(current).stop();
    }
  }

  function ensurePlayer(id) {
    if (!players[id]) {
      // TODO: Currently disabling preroll ads in modal/carousel as they
      //       don't play nice.
      if (videos.length > 0){
        if (videos[id].advertising_tag) {
          delete videos[id].advertising_tag;
        }
        players[id] = initPlayer(videos[id]);
      }
    }
    return players[id];
  }
};