/**
* Save rating
*/
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

module.exports = function (params) {
  var	practitionerRatingSubmitUrl = params.rating_submit_url,
    practitionerSlug = params.slug,
    practitionerRatingSubmitTrigger = $('.practitioner-rating-submit'),
    ratingComment = $('#rating-comment'),
    ratingCounterElement = $('#countDisplay'),
    ratingCookieId = params.rating_cookie,
    useRatingCookie = params.use_rating_cookie;
    
  var selector = ".hcf-starrating";
  var ratingListStarDisplay = ".hcf-starrating-listing"

	$(selector).each(function() {
    var ratingCaptions = { 
      1: 'Poor', 
      2: 'Okay', 
      3: 'Average', 
      4: 'Good', 
      5: 'Excellent' 
    }
    $(this).rating({size:'xs', step: 1, showClear: false, starCaptions: ratingCaptions });
  });
  
  $(ratingListStarDisplay).each(function() {
    var hcfStarCaptionClasses =     {
        0: 'hcf-star-caption',
        0.5: 'hcf-star-caption',
        1: 'hcf-star-caption',
        1.5: 'hcf-star-caption',
        2: 'hcf-star-caption',
        2.5: 'hcf-star-caption',
        3: 'hcf-star-caption',
        3.5: 'hcf-star-caption',
        4: 'hcf-star-caption',
        4.5: 'hcf-star-caption',
        5: 'hcf-star-caption'
    }
    var ratingCaptions = { 0: '0', 1: '1', 1.5: '1.5', 2: '2', 2.5: '2.5', 3: '3', 3.5: '3.5', 4: '4', 4.5: '4.5', 5: '5' }
    $(this).rating({size:'xxs', step: 0.5, showClear: false, starCaptions: ratingCaptions, starCaptionClasses: hcfStarCaptionClasses, clearCaption: '0' });
  });
  
  ratingComment.keyup(function() {
    ratingCounterElement.text($(this).val().length);
    
    if ($(this).val().length >= 50){
      ratingCounterElement.attr( 'class', 'hcf-rating-character-count-ok' );
    }
    else {
      ratingCounterElement.attr( 'class', 'hcf-rating-character-count-toolow' );
    }
  });
    
  practitionerRatingSubmitTrigger.on('click', function(e) {
    if (typeof ga !== 'undefined'){
      ga('send', 'event', 'practitioner-rating', practitionerSlug);
    }
    
    var informationRating = $("#rating-information").val();
    var educationRating = $("#rating-education").val();
    var localServiceRating = $("#rating-local-services").val();
    var actionPlanRating = $("#rating-action-plan").val();
    var comment = $("#rating-comment").val();
    
    // check if all values are correct
    var ratingThanksModal = $('#ratings-submit-thanks');
    var ratingText = $('#rating-text');
    
    if (informationRating < 1 || educationRating < 1 || localServiceRating < 1 || actionPlanRating < 1 ||
        comment.length < 50) {
      var ratingText = $('#rating-text');
      ratingText.html('The rating can not be submitted.</br></br>All rating categories must have at least one star selected.' + 
                      'The comments text must be longer than 50 characters.</br></br>' +
                      'Please check the values for your rating and then re-submit. Thank you.');
    }
    else if (useRatingCookie && Cookies.get(ratingCookieId) == 'true') {
      var ratingText = $('#rating-text');
      ratingText.html('You have already submitted a rating for this practitioner.</br></br>' +
                      'Thank you.');
    }
    else {
      //save rating 
      var ratingData = {
        slug: practitionerSlug,
        informationRating: informationRating,
        educationRating: educationRating,
        localServiceRating: localServiceRating,
        actionPlanRating: actionPlanRating,
        comment: comment,
        id: 0,
      };

      $.post(practitionerRatingSubmitUrl, ratingData)
        .done(function(result) {
          return false;
        })
        .fail(function(result) {
          return false;
        });
      
      //set cookie for this survey
      if (useRatingCookie) {
        Cookies.set(ratingCookieId, 'true', { expires: 365 });
      }
          
      // display thanks for the rating window
      ratingText.html('Thank you for your rating feedback.</br>');
      
      // refresh page after closing thank you modal
      ratingThanksModal.on('hide.bs.modal', function (event) { 
          window.location.reload(true);
        });
    }
    
    ratingThanksModal.modal('show');
    
    return false;
  });
};