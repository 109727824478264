/**
 * Control the submition of the video quiz form. Please be aware this script only work with one quiz.
 *
 * @param (array) quiz: questions, answers, etc about the quiz
 */


module.exports = function (params) {
  var quiz_options = document.querySelectorAll('.video-quiz input[type="radio"]');

  var countAnswered = function() {
    var answered = document.querySelectorAll('.video-quiz input[type="radio"]:checked');
    // Get all elements with the specified class name
    const ratingElements = document.querySelectorAll('.numeric-rating-value');
    //Get elements whose text content is not "0"
    const nonZeroRatingElements = Array.from(ratingElements).filter(element => element.textContent.trim() !== "0");


    if (questions.length === answered.length + nonZeroRatingElements.length) {
      submit.removeAttribute('disabled');
    }
    else {
      submit.setAttribute('disabled', 'disabled');
    }
  };

  var processAnswer = function(el) {
    var index = el.getAttribute('name'),
        options = document.querySelectorAll('.video-quiz input[name="' + index + '"]'),
        answer = (el.value === 'true') ? true : false,
        result = document.querySelectorAll('.video-quiz div.question-' + index + ' div.result'),
        explanation = document.querySelectorAll('.video-quiz div[name="explanation-' + index + '"]'),
        output = 'fa-times';

    // Disable answer
    Array.prototype.forEach.call(options, function(el){
      el.setAttribute('disabled', 'disabled');
    });

    // Check answer
    if (answer == answers[index]) {
      output = 'fa-check';
      correct_answers++;
    }
    else {
      if (explanation.length > 0) {
        explanation[0].classList.remove("hide-explanation");
      }
    }

    // Print output
    result[0].innerHTML = '<i class="fa ' + output + '"></i>';
  };

  var submitForm = function(evt) {
    var answered = document.querySelectorAll('.video-quiz input[type="radio"]:checked');
    var pdfPath = params.quiz.pdf_path

    Array.prototype.forEach.call(answered, function(el){
      processAnswer(el);
    });

    this.style.display = 'none';
    this.insertAdjacentHTML('afterend', '<p>You scored ' + correct_answers + ' / ' + questions.length + '</p>');
    evt.preventDefault();
	
	  //increment video check count in database
	  $.get(params.quiz.quizCountUrl || null);

    if (pdfPath){
      document.getElementById('quiz-pdf-container').classList.remove("hide-pdf");
    }
  };


  if (quiz_options.length) {
    var questions = params.quiz.questions,
        submit = document.querySelector('.video-quiz button[type="submit"]'),
        answers = [],
        correct_answers = 0;
        
    gtag('event', 'video_quiz', {
        'video_title': params.title
      });
    
    for (var i = 0; i < questions.length; i++) {
      answers[i] = (questions[i].true == 1) ? true : false;
    }

    Array.prototype.forEach.call(quiz_options, function(el){
      el.addEventListener('click', countAnswered);
    });

    submit.addEventListener('click', submitForm);
  }

  const numericRating = document.getElementById('numericRating');
  const numericRatingValue = document.getElementById('numericRatingValue');

  // Function to add event listener to elements with IDs starting with a specific string
  function addEventListenerToIdsStartingWith(prefix, eventType, eventHandler) {
    // Select all elements
    const allElements = document.querySelectorAll('*');

    // Loop through each element
    allElements.forEach(element => {
      // Check if the element's ID starts with the specified prefix
      if (element.id && element.id.startsWith(prefix)) {
        // Add the event listener
        element.addEventListener(eventType, eventHandler);
      }
    });
  }

  addEventListenerToIdsStartingWith('numericRating-', 'click', (e) => {
    if (e.target.classList.contains('rating-btn')) {
      const str = e.target.parentElement.id;
      const index = str.indexOf('-');
      const result = index !== -1 ? str.substring(index + 1) : '';
      const ratingElement = document.getElementById('numericRatingValue-' + result);
      const value = e.target.dataset.value;
      ratingElement.textContent = value;
      document.querySelectorAll('#numericRating-' + result  + ' .rating-btn').forEach(btn => {
        btn.classList.toggle('active', btn.dataset.value <= value);
      });
    }

    //Preventing page refresh
    e.preventDefault();
    countAnswered();
  });
};
